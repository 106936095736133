import * as React from "react";
import { genKey } from "../../utils/utils";
import { Trans, Translation } from 'react-i18next';
import { Stack, StackItem } from '@fluentui/react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { getTheme } from '@fluentui/react';


export interface IProps {
    elements: any[];
}

export interface ICardProps {
    image: any;
    description: string;
}

class Card extends React.Component<ICardProps, {}> {
    constructor(props: ICardProps) {
        super(props);
    }
    render() {
        const theme = getTheme();
        return (
            <StackItem grow={1}>
                
                <div style={{ minWidth: 360, maxWidth: 360, boxShadow: theme.effects.elevation16, padding: 0, backgroundColor: 'white' }}>
                    <div style={{minHeight: 250, maxHeight: 250, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Zoom zoomMargin={50}>
                            <img src={this.props.image} width='100%' ></img>
                        </Zoom>
                    </div>
                </div>

            </StackItem>
                
        );
    }
}

export class Approach extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }
    buildElements() {
        const cards: any[] = this.props.elements.map(element => {
            return <Card image={element.image} description={element.description} key={genKey("card")}/>;
        })
        return cards;
    }
    render() {
        const stackTokens = { childrenGap: 30 };

        const isMobile = document.documentElement.clientWidth < 800;
        return (
            <div>
                <div style={{ paddingLeft: isMobile ? 0 : 30}}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t("description.approachHeading").toUpperCase()}</Translation></h3>
                </div>
                <div style={{ paddingLeft: isMobile ? 0 : 30, paddingRight: isMobile ? 0 : 30, paddingTop: 30, paddingBottom: 30 }}>

                    <Stack tokens={{ childrenGap: 30 }}>
                        <div>
                            <Trans i18nKey="description.approachDescription"></Trans>
                        </div>

                    </Stack>
                    
                    {/*<div style={{ paddingTop: 20}}>*/}
                    {/*    <Stack horizontal={document.documentElement.clientWidth > 800} tokens={stackTokens}>*/}
                    {/*        {this.buildElements()}*/}
                    {/*    </Stack>*/}
                    {/*</div>*/}
                </div>

                
            </div>
        );
    }
}
import React from 'react';
import { Translation } from 'react-i18next';
import { genKey } from '../../utils/utils';

import Header from '../../cmps/Header';
import Content from '../../cmps/Content';
import Footer from '../../cmps/Footer';

import { NextSteps } from '../../cmps/EmployeeMicrositeCards/NextSteps';
import { ContactPersons } from '../../cmps/EmployeeMicrositeCards/ContactPersons';
import { Cards } from '../../cmps/Cards';
import { Approach } from '../../cmps/EmployeeMicrositeCards/Approach';


import workplace1 from '../../assets/images/office5.jpg';
import workplace2 from '../../assets/images/office4.jpg';
import workplace3 from '../../assets/images/workplace1.jpg';
import bossbar from '../../assets/images/bossbar.jpg';
import canteen1 from '../../assets/images/canteen4.png';
import canteen2 from '../../assets/images/canteen3.jpg';
import approach1 from '../../assets/images/approach1.jpg';
import approach2 from '../../assets/images/approach2.png';
import i18n from '../../i18n';
import { CardWelcome } from '../../cmps/EmployeeMicrositeCards/CardWelcome';
import Config from '../../config.json';


export default class EmployeeMicrosite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            token: '',
            employee: {},
            lng: i18n.language
        };

        this.onLanguageSwitch = this.onLanguageSwitch.bind(this);
    }

    getEmployee() {
        fetch(Config.REACT_APP_BACKEND_URL + "/employee?token=" + this.props.match.params.token)
            .then(res => res.json())
            .then(result => {
                //console.log("getEmployee");
                //console.log(result);
                this.setState({
                    isLoaded: true,
                    employee: result,
                });
            },
                error => {
                    console.log(error);
                    this.setState({
                        isLoaded: false
                    });
                }
            )
    }

    getInitials(fullName) {
        const allNames = fullName.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
        return initials;
    }

    getFirstName(fullname) {
        if (/\s/.test(fullname)) {
            var nameParts = fullname.split(' ');
            return nameParts[0].toUpperCase();
        }
        else {
            return fullname.toUpperCase();
        }
    }

    getContactPerson(employee) {
        var contactElementSuperior = [{
            name: employee.Superior,
            initials: this.getInitials(employee.Superior),
            position: '',
            description: '',
            tel: '',
            email: employee.SuperiorEmail
        }];

        return contactElementSuperior;
    };

    componentDidMount() {
        this.getEmployee();
    }

    onLanguageSwitch(lng) {
        //console.log("Employee onLanguageSwitch : " + lng);
        this.setState({
            lng: lng
        });
    }

    render() {
        const headerContent = (
            <div>
                <h1 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{t => t("description.welcome").toUpperCase()}</Translation><br />{this.state.employee.Name ? " " + this.getFirstName(this.state.employee.Name) : ""}!</h1>
            </div>
        );

        const workPlaceElements = [{
            image: bossbar,
            description: 'BOSS Bar'
        }, {
            image: workplace2,
            description: 'Office'
        }, {
            image: workplace3,
            description: 'Store'
        }];
        const canteenElements = [{
            image: canteen1,
            name: 'Fresh 1',
            description: 'onec ullamcorper nulla non metus auctor fringilla.'
        }, {
            image: canteen2,
            name: 'Hugo Deli',
            description: 'onec ullamcorper nulla non metus auctor fringilla.'
        }];
        const approachElements = [{
            image: approach1,
            name: 'Carpark',
            description: 'description.approachCarpark'
        }, {
            image: approach2,
            name: 'OrientationMap',
            description: 'description.approachOrientation'
        }];
        var content;
        //console.log("employee: " + this.state.employee)
        //console.log("content" + content);
        if (this.state.employee.Superior) {
            content = [
                (<NextSteps token={this.props.match.params.token} employee={this.state.employee} key={genKey("card")} language={this.state.lng} />),
                (<ContactPersons elements={this.getContactPerson(this.state.employee)} key={genKey("card")} />),
                (<Cards elements={workPlaceElements} heading="description.workplaceHeading" showInfos={false} cardsPerRow={3} key={genKey("card")} />),
                //(<Cards elements={canteenElements} heading="description.canteenHeading" showInfos={true} cardsPerRow={2} key={genKey("card")} />),
                (<Approach elements={approachElements} key={genKey("card")} />),
                (<CardWelcome heading="description.welcomeHeading" description={["description.welcomeP1", "description.welcomeP2"]} key={genKey("card")} />),
            ];
        }

        else {
            content = [
                (<NextSteps token={this.props.match.params.token} employee={this.state.employee} key={genKey("card")} language={this.state.lng} />),
                (<Cards elements={workPlaceElements} heading="description.workplaceHeading" description="description.workplaceDescription" showInfos={false} cardsPerRow={3} key={genKey("card")} />),
                //(<Cards elements={canteenElements} heading="description.canteenHeading" showInfos={true} cardsPerRow={2} key={genKey("card")} />),
                (<Approach elements={approachElements} key={genKey("card")} />),
                (<CardWelcome heading="description.welcomeHeading" description={["description.welcomeP1", "description.welcomeP2"]} key={genKey("card")} />),
            ];
        }

        console.log("Content after: " + content);

        return (
            <div>
                <Header headerContent={headerContent} />
                <Content content={content} />
                <Footer callback={this.onLanguageSwitch} />
            </div>
        );
    }
}
import React from 'react';
import { genKey } from '../../utils/utils';
import { Translation } from 'react-i18next';
import { Stack, Checkbox, ChoiceGroup } from '@fluentui/react';

import { CardsActive } from '../EmployeeCards/CardsActive';

import i18n from "../../i18n";

import { getTheme } from '@fluentui/react';
import Config from '../../config.json';


interface IProps {
    heading: string;
    mobiles: any[];
    tariffs: any[];
    mobileRequired: boolean;
    callbackRequired: any;
    selectedMobile: number;
    selectedTariff: number;
    callbackSelectMobile: any;
    callbackSelectTariff: any;
}

interface IState {
    mobileRequired: boolean;  
}

export class CardMobile extends React.Component <IProps , IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            mobileRequired: this.props.mobileRequired
        };
    }

    render() {
        const theme = getTheme();
        const styleCard = { 
            boxShadow: theme.effects.elevation16, 
            padding: 30, 
            backgroundColor: 'white' 
        };
        return (
            <Stack tokens={{childrenGap: 30}}>
                <div style={{ paddingLeft: 30}}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation></h3>
                </div>
                <div style={styleCard}>
                    <Checkbox 
                        key={genKey("cb_mobileRequired")} 
                        id="mobileRequired" 
                        label={i18n.t("description.manager.phoneRequired")} 
                        onChange={this.props.callbackRequired} 
                        checked={this.props.mobileRequired}
                    />                   
                </div>
                {
                    this.props.mobileRequired &&
                    <CardsActive 
                        heading="none"
                        elements={this.mapMobiles()}
                        lng={i18n.language}
                        showInfos={true}
                        cardsPerRow={3}
                    />
                }
                {
                    this.props.mobileRequired && this.props.selectedMobile &&
                    <div style={{boxShadow: theme.effects.elevation16, padding: 30}}>
                        <ChoiceGroup 
                            selectedKey={this.props.selectedTariff} 
                            options={this.mapTariffs()} 
                            onChange={this.props.callbackSelectTariff} 
                            label={i18n.t('description.manager.tariffs')} 
                        />
                    </div>

                }
            </Stack>


        );
    }

    
    mapMobiles() {
        const elements = this.props.mobiles.map(item => {
            const bullets = item.Konfiguration.split(",");
            bullets.push(item.Policy);

            return {
                id: item.Id,
                key: genKey("cardItemMobile"),
                name: item.Title,
                description: i18n.language === "de" ? item.Beschreibung : item.BeschreibungEn,
                image: this.jobPicture(item.Image),
                bullets: bullets,
                callback: this.props.callbackSelectMobile,
                active: this.props.selectedMobile === item.Id
            };
            
        });
        return elements;
    }

    mapTariffs() {
        const elements = this.props.tariffs.map(item => {
            return { 
                key: item.Id, 
                text: item.Title + ": " + (i18n.language === "de" ? item.Beschreibung : item.BeschreibungEn) 
            };
            
        });
        return elements;
    }


    jobPicture(data: any) {
        var url = JSON.parse(data);
        return Config.REACT_APP_BACKEND_URL + "/image?path=" + url.serverRelativeUrl;
    }
}
import * as React from "react";
import { genKey } from '../../utils/utils';
import { Translation } from 'react-i18next';
import { Stack, StackItem } from '@fluentui/react';
import { Persona, PersonaInitialsColor, PersonaSize } from '@fluentui/react';
import { Avatar } from "../Avatar";

import { getTheme } from '@fluentui/react';

export interface IContactPersonsProps {
    elements: any[];
}

export interface ICardProps {
    element: any;
}


class Card extends React.Component<ICardProps, {}> {
    constructor(props: ICardProps) {
        super(props);
    }
    render() {
        const theme = getTheme();
        const styleCardBox = {
            minWidth: 320,
            maxWidth: 320,
            boxShadow: theme.effects.elevation16, 
            padding: 20, 
            backgroundColor: 'white',
        };
        const avt = {
            name: this.props.element.name,
            initials: this.props.element.initials,
            image: this.props.element.image
        };
        //<Persona size={PersonaSize.size72} initialsColor={PersonaInitialsColor.gray} imageUrl={this.props.element.image}/>
                        
        return (
            <StackItem grow={1}>
                <div style={styleCardBox}>
                    <div>
                    <Avatar {...avt}/>
                        
                    </div> 
                    <div style={{ paddingTop: 20 }}>
                        {this.props.element.description}
                    </div>
                    <div style={{ paddingTop: 20, fontWeight: 'bold' }}>
                        <div>{this.props.element.tel}</div>
                        <div>{this.props.element.email}</div>
                    </div>
                </div>
            </StackItem>
            
        );
    }
}

export class ContactPersons extends React.Component<IContactPersonsProps, {}> {
    constructor(props: IContactPersonsProps) {
        super(props);
    }
    buildElements() {
        const cards: any[] = this.props.elements.map(element => {
            return <Card element={element} key={genKey("card")}/>;
        })
        return cards;
    }
    render() {
        const theme = getTheme();
        const stackTokens = { childrenGap: 60 };
        const isMobile = document.documentElement.clientWidth < 800;
        return (
            <div >
                <div style={{ paddingLeft: isMobile ? 0 : 30}}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t("description.contactPersonHeading").toUpperCase()}</Translation></h3>
                </div>
                
                <div style={{ paddingTop: 20}}>
                    <Stack horizontal={!isMobile} tokens={stackTokens}>
                        {this.buildElements()}
                    </Stack>
                </div>
                
            </div>
        );
    }
}
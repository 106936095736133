import React from "react";
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { HashRouter, Route, Switch } from "react-router-dom";
import { initializeIcons } from "@uifabric/icons";
import Manager from "./views/Manager/ManagerNew";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import Employee from "./views/Employee/EmployeeNew";
import MicrositeManager from "./views/Manager/MicrositeManagerNew";
import EmployeeMicrosite from "./views/Employee/EmployeeMicrositeNew";
import Error404 from "./views/Error404";
import TopBar from "./cmps/TopBar";


import styles from "./App.scss";


const languages = {
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' }
};

library.add(faPlaneDeparture);
initializeIcons();

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpened: false
        };

        this.onLanguageSwitch = this.onLanguageSwitch.bind(this);
    }

    componentDidMount() {

    }

    onLanguageSwitch(lng) {
        //console.log("Employee onLanguageSwitch : " + lng);
        this.setState({
            lng: lng
        });
    }

    render() {

        const activateFont = styles.activateFont; // pseudo referencing the style to activate fontface

        const hbTheme = {
            palette: {
                themeDarker: "#564028",
                themeDark: "#745637",
                themeDarkAlt: "#8a6641",
                themePrimary: "#987147",
                themeSecondary: "#a58058",
                themeTertiary: "#c2a484",
                themeLight: "#e0cfbd",
                themeLighter: "#efe5da",
                themeLighterAlt: "#fbf8f6",
                black: "#000000",
                blackTranslucent40: "rgba(0,0,0,.4)",
                neutralDark: "#201f1e",
                neutralPrimary: "#323130",
                neutralPrimaryAlt: "#3b3a39",
                neutralSecondary: "#605e5c",
                neutralSecondaryAlt: "#8a8886",
                neutralTertiary: "#a19f9d",
                neutralTertiaryAlt: "#c8c6c4",
                neutralQuaternary: "#d0d0d0",
                neutralQuaternaryAlt: "#e1dfdd",
                neutralLight: "#edebe9",
                neutralLighter: "#f3f2f1",
                neutralLighterAlt: "#ffffff",
                accent: "#987147",
                white: "#ffffff",
                whiteTranslucent40: "rgba(255,255,255,.4)",
            },
            semanticColors: {
                bodyBackground: "#ffffff",
                bodyFrameBackground: "#ffffff",
                accentButtonText: "#ffffff",
                buttonBackground: "#ffffff",
                primaryButtonText: "#ffffff",
                primaryButtonTextHovered: "#ffffff",
                primaryButtonTextPressed: "#ffffff",
                inputBackground: "#ffffff",
                inputForegroundChecked: "#ffffff",
                listBackground: "#ffffff",
                menuBackground: "#ffffff",
                cardStandoutBackground: "#ffffff",
                bodyTextChecked: "#000000",
                buttonTextCheckedHovered: "#000000",
                link: "#987147",
                primaryButtonBackground: "#000000",
                inputBackgroundChecked: "#000000",
                inputIcon: "#987147",
                inputFocusBorderAlt: "#987147",
                menuIcon: "#987147",
                menuHeader: "#987147",
                accentButtonBackground: "#987147",
                primaryButtonBackgroundPressed: "#000000",
                inputBackgroundCheckedHovered: "#252525",
                inputIconHovered: "#745637",
                linkHovered: "#564028",
                primaryButtonBackgroundHovered: "#252525",
                inputPlaceholderBackgroundChecked: "#efe5da",
                bodyBackgroundChecked: "#edebe9",
                bodyFrameDivider: "#edebe9",
                bodyDivider: "#edebe9",
                variantBorder: "#edebe9",
                buttonBackgroundCheckedHovered: "#edebe9",
                buttonBackgroundPressed: "#edebe9",
                listItemBackgroundChecked: "#edebe9",
                listHeaderBackgroundPressed: "#edebe9",
                menuItemBackgroundPressed: "#edebe9",
                menuItemBackgroundChecked: "#edebe9",
                bodyBackgroundHovered: "#f3f2f1",
                buttonBackgroundHovered: "#f3f2f1",
                buttonBackgroundDisabled: "#f3f2f1",
                buttonBorderDisabled: "#f3f2f1",
                primaryButtonBackgroundDisabled: "#f3f2f1",
                disabledBackground: "#f3f2f1",
                listItemBackgroundHovered: "#f3f2f1",
                listHeaderBackgroundHovered: "#f3f2f1",
                menuItemBackgroundHovered: "#f3f2f1",
                primaryButtonTextDisabled: "#d0d0d0",
                disabledSubtext: "#d0d0d0",
                listItemBackgroundCheckedHovered: "#e1dfdd",
                disabledBodyText: "#a19f9d",
                variantBorderHovered: "#a19f9d",
                buttonTextDisabled: "#a19f9d",
                inputIconDisabled: "#a19f9d",
                disabledText: "#a19f9d",
                bodyText: "#323130",
                actionLink: "#323130",
                buttonText: "#323130",
                inputBorderHovered: "#323130",
                inputText: "#323130",
                listText: "#323130",
                menuItemText: "#323130",
                bodyStandoutBackground: "#ffffff",
                defaultStateBackground: "#ffffff",
                actionLinkHovered: "#201f1e",
                buttonTextHovered: "#201f1e",
                buttonTextChecked: "#201f1e",
                buttonTextPressed: "#201f1e",
                inputTextHovered: "#201f1e",
                menuItemTextHovered: "#201f1e",
                bodySubtext: "#605e5c",
                focusBorder: "#605e5c",
                inputBorder: "#605e5c",
                smallInputBorder: "#000000",
                inputPlaceholderText: "#605e5c",
                buttonBorder: "#8a8886",
                disabledBodySubtext: "#c8c6c4",
                disabledBorder: "#c8c6c4",
                buttonBackgroundChecked: "#c8c6c4",
                menuDivider: "#c8c6c4",
                cardShadow: "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
                cardShadowHovered: "0 0 1px #a19f9d",
                primaryButtonBorder: "transparent",
                errorText: "#a4262c",
                messageText: "#323130",
                messageLink: "#005A9E",
                messageLinkHovered: "#004578",
                infoIcon: "#605e5c",
                errorIcon: "#A80000",
                blockingIcon: "#FDE7E9",
                warningIcon: "#797775",
                severeWarningIcon: "#D83B01",
                successIcon: "#107C10",
                infoBackground: "#f3f2f1",
                errorBackground: "#FDE7E9",
                blockingBackground: "#FDE7E9",
                warningBackground: "#FFF4CE",
                severeWarningBackground: "#FED9CC",
                successBackground: "#DFF6DD",
                warningHighlight: "#ffb900",
                successText: "#107C10",
                listTextColor: "#323130",
                warningText: "#323130"
            },
            defaultFontStyle: {
                fontFamily: 'AvertaPE-Light',
                fontWeight: 'normal'
            },
            fonts: {
                large: {
                    fontFamily: 'AvertaPE-Extrabold',
                    fontSize: 42
                },
                medium: {
                    fontSize: 15
                    }
            }
        };

        return (
            <ThemeProvider applyTo='body' theme={hbTheme}>
                <HashRouter>
                    <TopBar callback={this.onLanguageSwitch}/>
                    <Switch>
                        <Route exact path="/Manager" component={Manager}/>
                        <Route exact path="/MicrositeManager/:selectTemplateSuccess?" component={MicrositeManager} />
                        <Route exact path="/Employee/:token" component={Employee} />
                        <Route exact path="/EmployeeMicrosite/:token" component={EmployeeMicrosite} />
                        <Route path={["/","/*"]} render={() => <Error404 error="500"/>} />
                    </Switch>
                </HashRouter>
            </ThemeProvider>
        );
    }
}
export default App;

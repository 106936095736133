import React from 'react';
import { Stack } from '@fluentui/react';

interface IProps {
    lng?: string;
    content: any[];
}

export default class Content extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        //alert(document.documentElement.clientWidth);
    }

    render() {
        const isMobile = document.documentElement.clientWidth < 800;
        const style: any = {
            marginTop: isMobile ? 60 : 30,
            width: document.documentElement.clientWidth < 800 ? 360 : 1270,
            overflowX: 'auto',
            overflowY: 'hidden',
            padding: 5

        };
        const stackTokens = { childrenGap: 60 };
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={style}>
                    <div style={{ maxWidth: 1200 }}>
                        <Stack tokens={stackTokens}>
                            {this.props.content}
                        </Stack>
                    </div>

                </div>
            </div>
        )
    }
}
import * as React from "react";
import { genKey } from '../utils/utils';
import { Translation } from 'react-i18next';
import { Stack, StackItem } from '@fluentui/react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { getTheme } from '@fluentui/react';

export interface IProps {
    heading: string;
    elements: any[];
    showInfos: boolean;
    cardsPerRow: number;
    descriptionHeading?: string;
    description?: string;
}

export interface ICardProps {
    element: any;
    showInfos: boolean;
    width: number;
}

class Card extends React.Component<ICardProps, {}> {
    constructor(props: ICardProps) {
        super(props);
    }
    render() {
        const theme = getTheme();
        return (
            <StackItem grow={1}>
                <div style={{ minWidth: this.props.width, maxWidth: this.props.width, boxShadow: theme.effects.elevation16, padding: 0, backgroundColor: 'white' }}>
                    <div style={{ maxHeight: 250, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Zoom zoomMargin={50}>
                            <img src={this.props.element.image} width='100%' ></img>
                        </Zoom>
                    </div>
                    {
                        this.props.showInfos &&
                        <div style={{ padding: 30 }}>
                            {
                                this.props.element.name &&
                                <div style={{ fontWeight: 'bold' }}>
                                    {this.props.element.name}
                                </div>
                            }
                            {
                                this.props.element.description &&
                                <div>
                                    {this.props.element.description}
                                </div>
                            }
                            {
                                this.props.element.footer &&
                                <div>
                                    {this.props.element.footer}
                                </div>
                            }
                        </div>
                    }
                </div>
            </StackItem>

        );
    }
}

export class Cards extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }
    buildElements() {
        const width = this.props.cardsPerRow === 3 ? 360 : 585;
        const cards: any[] = this.props.elements.map(element => {
            return <Card element={element} showInfos={this.props.showInfos} width={width} key={genKey("card")} />;
        })
        return cards;
    }
    render() {
        const stackTokens = { childrenGap: 60 };
        const isMobile = document.documentElement.clientWidth < 800;
        return (
            <div>
                <div style={{ paddingLeft: isMobile ? 0 : 30 }}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation></h3>
                </div>
                {
                    this.props.descriptionHeading &&
                    <div style={{ paddingLeft: isMobile ? 0 : 45, paddingTop: 15, fontWeight: 'bold' }}>
                        <Translation>{(t) => t(this.props.descriptionHeading)}</Translation>
                    </div>
                }
                {
                    this.props.description &&
                    <div style={{ paddingLeft: isMobile ? 0 : 30, paddingRight: isMobile ? 0 : 30, paddingTop: 30 }}>
                        <Translation>{(t) => t(this.props.description)}</Translation>
                    </div>
                }

                <div style={{ paddingTop: 20 }}>
                    <Stack horizontal={!isMobile} tokens={stackTokens}>
                        {this.buildElements()}
                    </Stack>
                </div>

            </div>
        );
    }
}
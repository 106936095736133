import React from "react";
import { withMsal } from '@azure/msal-react';
import { Translation } from 'react-i18next';

import { login } from '../../utils/utils';
import { genKey } from '../../utils/utils';

import Header from '../../cmps/Header';
import Content from '../../cmps/Content';
import Footer from '../../cmps/Footer';

import { CardHello } from '../../cmps/EmployeeCards/CardHello';
import { CardsActive } from '../../cmps/EmployeeCards/CardsActive';
import { CardTemplateOptions } from '../../cmps/Manager/CardTemplateOptions';
import { CardMobile } from '../../cmps/Manager/CardMobile';
import { CardSave } from '../../cmps/Manager/CardSave';
import { CardNote } from '../../cmps/Manager/CardNote';
import Config from '../../config.json';

import i18n from "../../i18n";

class Manager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            templates: [],
            selectedTemplate: null,
            selectedMobilePhone: null,
            bemerkung: "",
            bemerkungEnabled: false,
            templateOptions: [],
            selectedTemplateOptions: [],
            status: '',
            employee: {},
            mobileRequired: false,
            mobilePhoneOptions: [],
            mobilePhoneTariffs: [],
            selectedMobilePhoneTariff: null,
            lng: i18n.language,
            saveDisabled: false
        }

        this.loadData = this.loadData.bind(this);
        this.onLanguageSwitch = this.onLanguageSwitch.bind(this);
        this.onChangeTemplate = this.onChangeTemplate.bind(this);
        this.onChangeSoftware = this.onChangeSoftware.bind(this);
        this.onChangeMobileRequired = this.onChangeMobileRequired.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onChangeTariff = this.onChangeTariff.bind(this);
        this.onChangeNoteEnabled = this.onChangeNoteEnabled.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        console.log(props);
    }

    loadData() {
        this.getTemplates();
        this.getMobilePhones();
        this.getMobileTariffs();
    }

    componentDidMount() {
        login(this.props.msalContext, this.loadData);
    }

    onLanguageSwitch(lng) {
        //console.log("Employee onLanguageSwitch : " + lng);
        this.setState({
            ...this.state, lng: lng
        });
    }

    onChangeTemplate(event) {
        this.setState({
            ...this.state, selectedTemplate: parseInt(event.currentTarget.value)
        }, this.getTemplateOptions);
    }

    onChangeSoftware(event, isChecked) {
        //console.log("in onChangeSoftware");
        var options = this.state.selectedTemplateOptions;
        if (isChecked) {
            options.push(event.target.id);
        } else {
            options = options.filter(function (e) { return e !== event.target.id; });
        }
        //console.log(options);
        this.setState({
            ...this.state, selectedTemplateOptions: options
        });
    }

    onChangeMobileRequired(event, isChecked) {
        const selectedMobilePhone = this.state.selectedMobilePhone;
        const selectedMobileTariff = this.state.selectedMobilePhoneTariff;
        this.setState({ ...this.state, mobileRequired: isChecked, selectedMobilePhone: isChecked ? selectedMobilePhone : null, selectedMobilePhoneTariff: isChecked ? selectedMobileTariff : null });
    }

    onChangeMobile(event) {
        this.setState({ ...this.state, selectedMobilePhone: parseInt(event.currentTarget.value) });
    }

    onChangeTariff(event, option) {
        this.setState({ ...this.state, selectedMobilePhoneTariff: parseInt(option.key) });
    }  
    
    onChangeNote(event) {
        //console.log("onChangeNote");
        //console.log(event.target.value);
        this.setState({ ...this.state, bemerkung: event.target.value });
    }

    onChangeNoteEnabled(event, isChecked) {
        this.setState({ ...this.state, bemerkungEnabled: isChecked });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.selectedTemplate) {
            this.setState({ saveDisabled: true });
            this.save();
        } else {
            this.setState({ status: 'required' });
        }
    }

    render() {
        const managerName = this.props.msalContext.accounts.length > 0 ? this.getFirstName(this.props.msalContext.accounts[0].name) : "Manager";
        const headerContent = (
            <div>
                <h1 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{t => t("description.hey").toUpperCase()}</Translation></h1>
                <h1 style={{ fontFamily: 'AvertaPE-Extrabold' }}>{managerName.toUpperCase()}</h1>
            </div>
        );

        const templates = this.state.templates;

        const content = [
            <CardHello name={this.props.location.state.employeeName} heading="description.manager.welcomeHeading" description={["description.manager.welcomeText"]} key={genKey("managerCardHello")}/>,
            <CardsActive elements={this.mapDevices("templates", templates, this.onChangeTemplate, "selectedTemplate")} heading="description.manager.welcomeText1" showInfos={true} descriptionMax={80} key={genKey("managerCardsTemplate")} cardsPerRow={3} lng={this.state.lng}/>
        ];

        if(this.state.templateOptions.length > 0) {
            content.push(
                <CardTemplateOptions 
                    heading="description.manager.selectTemplateOptionHeading" 
                    templateOptions={this.state.templateOptions} 
                    selectedTemplateOptions={this.state.selectedTemplateOptions} 
                    callbackSelect={this.onChangeSoftware} 
                    key={genKey("managerCardsOptions")}
                />
            );
        }

        if(this.state.templateOptions.length > 0) {
            content.push(
                <CardMobile 
                    heading="description.manager.selectMobileHeading"  
                    mobiles={this.state.mobilePhoneOptions}
                    tariffs={this.state.mobilePhoneTariffs}
                    mobileRequired={this.state.mobileRequired}
                    callbackRequired={this.onChangeMobileRequired} 
                    callbackSelectMobile={this.onChangeMobile} 
                    callbackSelectTariff={this.onChangeTariff} 
                    selectedMobile={this.state.selectedMobilePhone}
                    selectedTariff={this.state.selectedMobilePhoneTariff}
                    key={genKey("managerCardsMobile")}
                />
            );
        }

        if(this.state.templateOptions.length > 0) {
            content.push(
                <CardNote 
                    noteEnabled={this.state.bemerkungEnabled} 
                    callbackEnable={this.onChangeNoteEnabled} 
                    note={this.state.bemerkung}
                    callbackChangeNote={this.onChangeNote}
                    key={"managerCardNote"}
                />
            );
            content.push(
                <CardSave disabled={this.state.saveDisabled} key={genKey("managerCardSave")}/>
            );
        }

        return (
            <div>
                
                <Header headerContent={headerContent} />
                <form onSubmit={this.handleSubmit}>
                    <Content content={content} />
                </form>
                <Footer callback={this.onLanguageSwitch} />
            </div>
        );
    }

    getFirstName(name) {
        var nameArr = name.split(",");
        return nameArr[1].toUpperCase();
    }

    jobPicture(data) {
        var url = JSON.parse(data);
        return Config.REACT_APP_BACKEND_URL + "/image?path=" + url.serverRelativeUrl;
    }

    mapDevices(type, devices, callback, stateName) {
        const lng = this.state.lng;
        const elements = devices.map(item => {

            return {
                id: item.Id,
                key: type + "_" + item.Id,
                name: lng === "de" ? item.Templatename : item.TemplatenameEn,
                description: lng === "de" ? item.Beschreibung : item.BeschreibungEn,
                image: this.jobPicture(item.Templatebild),
                callback: callback,
                active: this.state[stateName] === item.Id
            };
            
        });
        //console.log("mapDevices lng : " + lng);
        //console.log(elements);
        return elements;
    }

    getTemplates() {
        fetch(Config.REACT_APP_BACKEND_URL + "/Templates", {
            credentials: "include",
            headers: {
                accept: "application/json;odata=verbose"
            }
        })
            .then(res => res.json())
            .then(res => {
                this.setState({ templates: res });
            });
    }

    getTemplateOptions() {
        fetch(Config.REACT_APP_BACKEND_URL + "/TemplateOptions?templateId=" + this.state.selectedTemplate, {
            credentials: "include",
            headers: {
                accept: "application/json;odata=verbose"
            }
        })
            .then(res => res.json())
            .then(res => {
                this.setState({ templateOptions: res, selectedTemplateOptions: [] });
            })
    }

    getMobilePhones() {
        fetch(Config.REACT_APP_BACKEND_URL + "/MobilePhones", {
            credentials: "include",
            headers: {
                accept: "application/json;odata=verbose"
            }
        })
            .then(res => res.json())
            .then(res => {
                this.setState({ mobilePhoneOptions: res });
            })
    }

    getMobileTariffs() {
        fetch(Config.REACT_APP_BACKEND_URL + "/MobilePhoneTariffs", {
            credentials: "include",
            headers: {
                accept: "application/json;odata=verbose"
            }
        })
            .then(res => res.json())
            .then(res => {
                this.setState({ mobilePhoneTariffs: res });
            })
    }

    save() {
        const account = this.props.msalContext.accounts[0];
        const accessTokenRequest = {
            scopes: [Config.REACT_APP_MSAL_SCOPE],
            account: account,
        };
        var that = this;
        this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest)
            .then(function (response) {
                fetch(Config.REACT_APP_BACKEND_URL + "/manager", {
                    method: "POST",
                    body: JSON.stringify({
                        token: that.props.location.state.token,
                        templateId: that.state.selectedTemplate,
                        templateOptions: that.state.selectedTemplateOptions,
                        mobilePhoneId: that.state.selectedMobilePhone,
                        mobileTariffId: that.state.selectedMobilePhoneTariff,
                        note: that.state.bemerkung

                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + response.accessToken
                    }
                })
                    .then(() => {
                        that.props.history.push('/MicrositeManager/selectTemplateSuccess')
                    })
                    .catch((error) => {
                        that.setState({ status: 'error', error });
                    });
            });
    }

}

export default Manager = withMsal(Manager);

import Config from "../config.json";

export const msalConfig = {
    auth: {
        clientId: Config.REACT_APP_MSAL_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/' + Config.REACT_APP_MSAL_TENANT_ID,
        redirectUri: Config.REACT_APP_MSAL_REDIRECT_URL
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};


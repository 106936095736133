import React from 'react';
//import bgImage from "../assets/images/header_background.jpg";
import bgImage from "../assets/images/header2_edit.jpg";

interface IProps {
    headerContent: any;
}

interface IState {

}

export default class Header extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const isMobile = document.documentElement.clientWidth < 800;
        const styleHeader = {
            backgroundImage: 'url(' + bgImage + ')',
            height: isMobile ? 200 : 300,
            maxHeight: isMobile ? 200 : 500,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        };
        const styleContent: any = {
            width: 1210,
            padding: 5,
            overflowX: 'hidden',
            overflowY: 'hidden',
        }

        //key={() => "header_"+Date.now()}
        return (
            <div style={styleHeader}>
                <div style={styleContent} >
                    <div>
                        {this.props.headerContent}
                    </div>
                </div>
            </div>
        )
    }
}
import * as React from "react";
import { Translation } from 'react-i18next';
import { PrimaryButton, Stack, StackItem } from '@fluentui/react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { getTheme } from '@fluentui/react';



interface ICardProps {
    key: string;
    element: any;
    showInfos: boolean;
    //callback: any;
    bulletsMax?: number;
    descriptionMax?: number;
}

class Card extends React.Component<ICardProps, {}> {
    constructor(props: ICardProps) {
        super(props);
    }
    render() {
        const theme = getTheme();
        const styleCardBox = {
            minWidth: 360,
            maxWidth: 360,
            boxShadow: theme.effects.elevation16, 
            padding: 0, 
            backgroundColor: 'white',
            opacity: '100%',
            height: '100%'
        //    opacity: this.props.element.active ? '100%' : '50%'
        };
        const heightConfBox = this.props.bulletsMax;
        const styleConfBox = {
            padding: 20, 
            minHeight: heightConfBox,
            maxHeight: heightConfBox,
            overflow: 'hidden'
        };
        const styleDescription: any = this.props.descriptionMax ? {
            minHeight: this.props.descriptionMax,
            maxHeight: this.props.descriptionMax,
            overflowY: 'hidden'
        } : {};
        return (
            <Translation>{t => 
            <StackItem key={this.props.element.key}>
                <div style={styleCardBox}>
                    <div style={{minHeight:200, maxHeight: 200, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Zoom zoomMargin={50}>
                            <img src={this.props.element.image} width='100%' ></img>
                        </Zoom>
                    </div>
                    {
                        this.props.showInfos &&
                        <div style={{ padding: 30, paddingBottom: 0 }}>
                            {
                                this.props.element.name &&
                                <div style={{fontWeight: 'bold'}}>
                                    {this.props.element.name}
                                </div>
                            }
                            {
                                this.props.element.description &&
                                <div style={styleDescription}>
                                    {this.props.element.description}
                                </div>
                            }
                            {
                                this.props.element.bullets && this.props.element.bullets.length > 0 &&
                                <div style={styleConfBox}>
                                    <ul>
                                        {
                                            this.props.element.bullets.map((i: string) => {
                                                const key = "cardBullet_"+Math.floor(Math.random() * 1000000);
                                                return (
                                                    <li key={key}>{i}</li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div> 
                            }
                        </div>
                    }
                    <div style={{padding: 30 }}>
                        <PrimaryButton value={this.props.element.id} checked={this.props.element.active} style={{width: "100%"}} text={this.props.element.active ? t("description.selected") : t("description.select")} type='button' onClick={this.props.element.callback} />
                    </div>
                </div>
            </StackItem>
            }</Translation>
            
        );
    }
}



interface IProps {
    lng: string;
    heading: string;
    elements: any[];
    showInfos: boolean;
    //callback: any;
    cardsPerRow: number;
    description?: string;
    descriptionMax?: number;
    bulletsMax?: number;

}

export class CardsActive extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }
    buildElements() {
        const cards: any[] = this.props.elements.map(element => {
            //console.log("CardsActive buildElements : " + this.props.lng);
            //console.log(element);
            return <Card 
                    element={element} 
                    showInfos={this.props.showInfos} 
                    bulletsMax={this.props.bulletsMax} 
                    descriptionMax={this.props.descriptionMax} 
                    //callback={this.props.callback} 
                    key={element.key}/>;
            
        })
        return cards;
    }
    render() {
        const theme = getTheme();
        const stackTokens = { childrenGap: 60, margin: 0 };
        const isMobile = document.documentElement.clientWidth < 800;
        return (
            <div>
                {
                   this.props.heading !== "none" &&
                   <div style={{ paddingLeft: isMobile ? 0 : 30}}>
                        <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation></h3>
                    </div>                   
                }

                {
                    this.props.cardsPerRow === 3 ? 
                    <div style={{ paddingTop: 20 }}>
                        <Stack horizontal wrap tokens={stackTokens}>
                            {this.buildElements()}
                        </Stack>
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        
                        <div style={{ paddingTop: 20, maxWidth: 800 }}>
                            <Stack tokens={stackTokens}>
                                <div>
                                    <Translation>{t => t(this.props.description)}</Translation>
                                </div>
                                <Stack horizontal wrap tokens={stackTokens}>
                                    {this.buildElements()}
                                </Stack>
                            </Stack>
                            
                        </div>
                    </div>

                }
                
                
            </div>
        );
    }
}
import * as React from "react";
import { genKey } from '../../utils/utils';
import { Translation } from 'react-i18next';

import { getTheme } from '@fluentui/react';

export interface IProps {
    heading: string;
    description: string[];
    name: string;
}

export class CardHello extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const theme = getTheme();
        const isMobile = document.documentElement.clientWidth < 800 ? true : false;
        return (
            <div style={{ boxShadow: theme.effects.elevation16, padding: 20, backgroundColor: 'white', width: isMobile ? 320 : 1160 }}>
                <div>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation>{this.props.name ? this.props.name.toUpperCase() : ""}</h3>
                    {
                        this.props.description.map(description => {
                            return (
                                <div style={{ paddingTop: 10}} key={genKey("cardItem")}>
                                    <Translation>{(t) => t(description)}</Translation>
                                </div>
                            );
                        })
                    }

                </div>
            </div>
        );
    }
}
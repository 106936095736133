import * as React from "react";
import { genKey } from '../../utils/utils';
import { Stack, Checkbox, TextField } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import i18n from "../../i18n";

export interface IProps {
    noteEnabled: boolean;
    callbackEnable: any;
    note: string;
    callbackChangeNote: any;
}

export class CardNote extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const theme = getTheme();
        return (
            <div style={{ boxShadow: theme.effects.elevation16, padding: 30, backgroundColor: 'white' }}>
                <Stack tokens={{childrenGap: 30}}>
                    <Checkbox 
                        key={genKey("cb_noteEnabled")} 
                        id="cb_noteEnabled" 
                        label={i18n.t("description.manager.noteEnable")} 
                        onChange={this.props.callbackEnable} 
                        checked={this.props.noteEnabled}
                    /> 
                    {
                        this.props.noteEnabled &&
                        <TextField 
                            multiline 
                            resizable={false} 
                            value={this.props.note}
                            onChange={this.props.callbackChangeNote}
                        />
                    }
                </Stack>
            </div>
        );
    }
}
import * as React from 'react';
import i18n from 'i18next';

import { Stack } from '@fluentui/react';
import { PrimaryButton, TextField, Icon } from '@fluentui/react';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { Link } from "react-router-dom";

import { Trans, Translation } from 'react-i18next';
import { getStatusColor } from '../../utils/utils';

import { getTheme } from '@fluentui/react';


export interface IDetailsListEmployeeDataGridState {
    columns: IColumn[];
    error: any;
    rowHovered: any;
}

export interface IData {
    Id: number;
    Name: string;
}

export interface IDetailsDataGridProps {
    token: string;
    employee: any[];
    language: string;
}

export class NextSteps extends React.Component<IDetailsDataGridProps, IDetailsListEmployeeDataGridState> {


    constructor(props: IDetailsDataGridProps) {
        super(props);
        const columns: IColumn[] = [
            {
                key: 'toDoHeading',
                name: i18n.t("description.toDoHeading"),
                fieldName: 'Mitarbeitername',
                minWidth: 30,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onRender: (item) => {
                    return (
                        <div style={{height:'100%', display: 'flex', alignItems: 'center'}}>
                            <Trans i18nKey={"description.selectHardware"}></Trans>
                        </div>
                    );
                },
                isPadded: true,
            },
            {
                key: 'additionalInfoHeading',
                name: i18n.t("description.additionalInfoHeading"),
                fieldName: '',
                minWidth: 30,
                maxWidth: 220,
                isRowHeader: true,
                isResizable: true,
                onRender: (item) => {
                    return (
                        <div style={{height:'100%', display: 'flex', alignItems: 'center'}}>
                            <Trans i18nKey={"description.additionalInfo"}></Trans>
                        </div>
                    );
                },
                isPadded: true,
            },
            {
                key: 'statusHeading',
                name: i18n.t("description.statusHeading"),
                fieldName: 'status',
                isRowHeader: true,
                minWidth: 30,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {

                    return (
                        <div style={{height:'100%', display: 'flex', alignItems: 'center', }}>
                            <span style={{
                                    padding: 5, 
                                    borderRadius: 5, 
                                    color: getStatusColor(item.Status)
                                    }}
                            >
                                {item.Status && <Trans i18nKey={'description.' + item.Status}></Trans>}
                            </span>
                        </div>

                    );
                    
                }
            },
            /*
            {
                key: 'nextStepsAction',
                name: i18n.t("description.nextStepsAction"),
                fieldName: 'action',
                isRowHeader: true,
                minWidth: 30,
                maxWidth: 220,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    const theme = getTheme();
                    if (item.Status === 'Warten auf Mitarbeiter') {
                        //console.log("action color " + theme.palette.themePrimary);
                        return (
                            <Link 
                                data-tip={item.Name} 
                                data-for={"Employee_" + item.Id} 
                                to={{ pathname: "/Employee/" + this.props.token + "", state: { token: item.Token, employeeName: item.Name, Id: item.Id } }}
                                //style= {{color: this.state.rowHovered === item.Id ? theme.palette.themePrimary : 'black'}}
                                onMouseEnter={()=>this.setState({rowHovered: item.Id})}
                                onMouseLeave={()=>this.setState({rowHovered: null})}
                            >
                                <PrimaryButton
                                    text={i18n.t("description.selectHardware").toUpperCase()}
                                    ariaLabel="select Hardware"
                                    iconProps={{ iconName: 'Forward'}}
                                    size={1}
                                />
                            </Link>
                        );
                    } else {
                        return <div></div>
                    }
                },
            },
            */
            {
                key: 'nextStepsDeadline',
                name: i18n.t("description.nextStepsDeadline"),
                fieldName: 'Deadline',
                isRowHeader: true,
                minWidth: 30,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item) => {
                    let deadline = "";
                    if(item.Deadline) {
                        const deadlineDate = new Date(Date.parse(item.Deadline));
                        deadline = new Intl.DateTimeFormat(i18n.language === 'de' ? 'de-DE' : 'en-US').format(deadlineDate)
                    }
                    
                    return (
                        <div style={{height:'100%', display: 'flex', alignItems: 'center'}}>
                            {deadline}
                        </div>
                    );
                }
            },
        ];

        this.state = {
            columns: columns,
            error: null,
            rowHovered: null,
        };
    }


    public render() {
        const { error, columns } = this.state;



        const theme = getTheme();
        const stackTokens = { childrenGap: 30 };

        const item: any = this.props.employee; // just temporary for the actionbutton under the table which only shows 1 todo atm
        let deadline = "";
        if(item.Deadline) {
            const deadlineDate = new Date(Date.parse(item.Deadline));
            deadline = new Intl.DateTimeFormat(i18n.language === 'de' ? 'de-DE' : 'en-US').format(deadlineDate)
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        } else {
            const isMobile = document.documentElement.clientWidth < 800 ? true : false;
            const cardStyle = {
                boxShadow: theme.effects.elevation16, 
                padding: 20, 
                backgroundColor: 'white',
                width: isMobile ? 320 : 1160
            }
            return (
                <div style={cardStyle}>
                    <Stack tokens={stackTokens}>
                        <h3 style={{ fontFamily: 'AvertaPE-Extrabold'}}>
                            <Translation>{t => t("description.nextSteps").toUpperCase()}</Translation>
                        </h3>
                        <div>
                            <Translation>{t => t("description.nextStepsDescription")}</Translation>
                        </div>
                        {
                            isMobile ?
                            <div>
                                <TextField 
                                    label={i18n.t("description.toDoHeading")}
                                    resizable={false} 
                                    value={i18n.t("description.additionalInfo")} 
                                    disabled
                                />
                                { 
                                    false &&
                                    <TextField 
                                        label={i18n.t("description.statusHeading")}
                                        resizable={false} 
                                        //value={i18n.t("description.additionalInfo")} 
                                        disabled
                                        onRenderInput={() => {

                                            return (
                                                <div style={{width:'100%', display: 'flex', justifyContent: 'left', color: getStatusColor(item.Status)}}>
                                                    <span style={{
                                                            padding: 5, 
                                                            paddingLeft: 10,
                                                            borderRadius: 5, 
                                                            color: getStatusColor(item.Status)
                                                            }}
                                                    >
                                                        {item.Status && item.Status != "Warten auf Mitarbeiter" && <Trans i18nKey={'description.' + item.Status}></Trans>}
                                                        {item.Status && item.Status == "Warten auf Mitarbeiter" && <Translation>{t => t("description.statusOpen")}</Translation> }
                                                    </span>
                                                </div>
                        
                                            );
                                            
                                        }}
                                    />

                                }
                                {
                                    item.Status === 'Warten auf Mitarbeiter' &&
                                    <TextField 
                                        label={i18n.t("description.nextStepsDeadline")}
                                        resizable={false} 
                                        value={deadline} 
                                        disabled
                                    />
                                }
                                
                            </div>
                            :
                            <DetailsList
                                items={[item]}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                getKey={this._getKey}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                            />
                        }
                        

                        {
                            item.Status === 'Warten auf Mitarbeiter' &&
                            <Link data-tip={item.Name} data-for={"Employee_" + item.Id} to={{ pathname: "/Employee/" + this.props.token + "", state: { token: item.Token, employeeName: item.Name, Id: item.Id, shipping: item.Shipping } }}>
                                <Stack horizontal tokens={{childrenGap: 20}}>
                                    {/*<div style={{height: 32, display: 'flex', alignItems: 'center'}}>*/}
                                    {/*    <div style={{height: 16}}><h5 style={{fontFamily: 'AvertaPE-Extrabold' , textDecoration: 'none'}}><Translation>{t => t("description.selectHardware").toUpperCase()}</Translation></h5></div>*/}
                                    {/*</div>*/}
                                        <PrimaryButton
                                            text={i18n.t("description.selectHardware").toUpperCase()}
                                            ariaLabel="select Hardware"
                                            iconProps={{ iconName: 'Forward'}}
                                        />
                                </Stack>
                            </Link>

                            
                        }

                        {
                            item.Status === 'Abgeschlossen' &&
                            <div style={{width:'100%', display: 'flex', justifyContent: 'center', color: getStatusColor(item.Status)}}>
                                <Icon iconName="CheckMark" style={{fontSize: 50}}/>
                            </div>
                        }
                        

                    </Stack>

                </div>

            );
        }
    }

    private _getKey(item: any, index?: number): string {
        return item.key;
    }
}


import React from 'react';
import logo from "../assets/images/HUGO-BOSS_black.svg";
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IPropsLanguageSwitcher {
    lng: string;
    callback: any;
}

class LanguageSwitcher extends React.Component<IPropsLanguageSwitcher, {}> {

    constructor(props: IPropsLanguageSwitcher) {
        super(props);
    }

    onLanguageSwitchChange(lng: string) {
        //console.log("LanguageSwitcher , lng : " + lng);
        i18n.changeLanguage(lng);
        this.props.callback(lng);
        //window.location.reload();
    }

    render() {
        const clickableBold = {
            fontFamily: 'AvertaPE-Extrabold',
            cursor: 'pointer'
        }
        const clickableNormal = {
            fontWeight: 'normal',
            cursor: 'pointer'
        }
        return (
            <div style={{ color: 'black', fontSize: '15px' }}>
                <span style={this.props.lng === "de" ? clickableBold : clickableNormal} onClick={() => this.onLanguageSwitchChange("de")}>DE</span>
                <span>   |   </span>
                <span style={this.props.lng === "en" ? clickableBold : clickableNormal} onClick={() => this.onLanguageSwitchChange("en")}>EN</span>
            </div>
        );
    }
}

interface IPropsTopBar {
    callback: any;
}

interface IStateTopBar {
    lng: string;
}

export default class TopBar extends React.Component<IPropsTopBar, IStateTopBar> {
    constructor(props: IPropsTopBar) {
        super(props);
        this.state = {
            lng: i18n.language
        };
        this.callbackSwitchLng = this.callbackSwitchLng.bind(this);
    }

    callbackSwitchLng(lng: string) {
        //console.log("Footer callbackSwitchLng , lng : " + lng);
        this.setState({
            lng: lng
        }, this.props.callback(lng));

    }

    renderIconLink(url: string, icon: any) {
        return <a rel="noreferrer" href={url} target="_blank" style={{ color: 'white' }}><FontAwesomeIcon icon={icon} /></a>;
    }

    render() {
        const style = {
            fontFamily: 'AvertaPE-Extrabold',
            fontSize: 25,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        };

        const imgStyle = {
            height: '1.25rem',
            width: 'auto'
        };

        const styleLng: any = {
            width: '100%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'right',
            backgroundColor: 'transparent',
            padding: '5px',
            fontFamily: 'AvertaPE-Light',
            marginRight: '3rem'
        };

        return (
            <div style={style}>
                <img style={imgStyle} src={logo}></img>
                <div style={styleLng}>
                    <LanguageSwitcher lng={this.state.lng} callback={this.callbackSwitchLng} />
                </div>
            </div>
        )
    }
}
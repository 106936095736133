import React from "react";
import { Translation } from 'react-i18next';
import Content from '../cmps/Content';

interface IProps {
    error?: string;
}

class Error404 extends React.Component <IProps,{}> {

    constructor(props: IProps) {
        super(props);
    }


    render() {

        let heading = "description.error404Headline";
        let message = "description.error404Message";

        if(this.props.error === "500") {
            heading = "description.error500Headline";
            message = "description.error500Message";
        }

        const toRender = (
            <div style={{minHeight: 600, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'block', textAlign: 'center'}}>
                    <div>
                        <h3 style={{fontFamily: 'AvertaPE-Extrabold'}}>
                            <Translation>{t => t(heading)}</Translation>
                        </h3>
                    </div>

                    <div>
                        <Translation>{t => t(message)}</Translation>
                    </div>
                </div>

            </div>
        );

        const content = [
            (toRender),
        ];

        return (
            <div>
                <Content content={content} />
            </div>
        );
    }
}
export default Error404;
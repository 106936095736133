import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { Contact } from './Footer/Contact';
import { Legal } from './Footer/Legal';
import { Discover } from './Footer/Discover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translation } from 'react-i18next';

interface IPropsLanguageSwitcher {
    lng: string;
    callback: any;
}

class LanguageSwitcher extends React.Component<IPropsLanguageSwitcher, {}> {

    constructor(props: IPropsLanguageSwitcher) {
        super(props);
    }

    onLanguageSwitchChange(lng: string) {
        //console.log("LanguageSwitcher , lng : " + lng);
        i18n.changeLanguage(lng);
        this.props.callback(lng);
        //window.location.reload();
    }

    render() {
        const clickableBold = {
            fontWeight: 'bold',
            cursor: 'pointer'
        }
        const clickableNormal = {
            fontWeight: 'normal',
            cursor: 'pointer'
        }
        return (
            <div style={{ color: 'white' }}>
                <span style={this.props.lng === "de" ? clickableBold : clickableNormal} onClick={() => this.onLanguageSwitchChange("de")}>DE</span>
                <span>|</span>
                <span style={this.props.lng === "en" ? clickableBold : clickableNormal} onClick={() => this.onLanguageSwitchChange("en")}>EN</span>
            </div>
        );
    }
}

interface IPropsFooter {
    callback: any;
}

interface IStateFooter {
    lng: string;
    hoverImp: boolean;
    hoverData: boolean;
}

export default class Footer extends React.Component<IPropsFooter, IStateFooter> {
    constructor(props: IPropsFooter) {
        super(props);
        this.state = {
            lng: i18n.language,
            hoverImp: false,
            hoverData: false
        };
        this.callbackSwitchLng = this.callbackSwitchLng.bind(this);
    }

    callbackSwitchLng(lng: string) {
        //console.log("Footer callbackSwitchLng , lng : " + lng);
        this.setState({
            lng: lng
        }, this.props.callback(lng));

    }

    renderIconLink(url: string, icon: any) {
        return <a rel="noreferrer" href={url} target="_blank" style={{ color: 'white' }}><FontAwesomeIcon icon={icon} /></a>;
    }

    render() {
        const isMobile = document.documentElement.clientWidth < 800;
        const styleFooter: any = {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30,
            backgroundColor: 'transparent'
        };
        const styleContainer: any = {
            width: 1210,
            padding: 5,
            overflowX: 'hidden',
            overflowY: 'hidden'
        };
        const styleLng: any = {
            width: '100%',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'right',
            backgroundColor: 'transparent'
        };
        const styleContent = {
            minWidth: 1200,
            maxWidth: 1200,
            color: 'white'
        };
        const styleColumn = {
            minWidth: 380,
            padding: 30,
            color: 'white'
        };
        const stackTokens = { childrenGap: 30 };
        return (
            <div>
                <div style={styleFooter}>
                    <div style={{ padding: 30 }}>
                        <div>
                            <div>
                                <a onMouseEnter={() => this.setState({ hoverImp: true })} onMouseLeave={() => this.setState({ hoverImp: false })} style={this.state.hoverImp ? { color: "#987147" } : { color: "#000" }} rel="noreferrer" href={'https://group.hugoboss.com/en/legal-information/about-us'} target="_blank">
                                    <Translation>{t => t("description.footer.imprint")}</Translation>
                                </a>
                                <span style={{ color: '#987147' }}> | </span>
                                <a onMouseEnter={() => this.setState({ hoverData: true })} onMouseLeave={() => this.setState({ hoverData: false })} style={this.state.hoverData ? { color: "#987147" } : { color: "#000" }} rel="noreferrer" href={'https://group.hugoboss.com/en/legal-information/privacy-policy'} target="_blank">
                                    <Translation>{t => t("description.footer.privacy")}</Translation>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React from 'react';
import { genKey } from '../../utils/utils';
import { Translation } from 'react-i18next';
import { Stack, Checkbox } from '@fluentui/react';

import i18n from "../../i18n";

import { getTheme } from '@fluentui/react';


interface IProps {
    heading: string;
    templateOptions: any[];
    selectedTemplateOptions: any[];
    callbackSelect: any;
}

interface IState {
    
}

export class CardTemplateOptions extends React.Component <IProps , IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const theme = getTheme();
        const styleCard = { 
            boxShadow: theme.effects.elevation16, 
            padding: 30, 
            backgroundColor: 'white' 
        };
        return (
            <Stack tokens={{childrenGap: 30}}>
                <div style={{ paddingLeft: 30}}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation></h3>
                </div>
                <div style={styleCard}>
                    <Stack tokens={{childrenGap: 30}}>
                        {this.props.templateOptions.map(i => {
                            const checked = this.props.selectedTemplateOptions.includes(""+i.Id);
                            return <Checkbox 
                                        key={genKey("selectTemplateOptionItem")} 
                                        id={i.Id} label={i18n.language === "de" ? i.Title : i.TitelEn} 
                                        onChange={this.props.callbackSelect} 
                                        checked={checked}
                                    />;
                        })}
                    </Stack>
                </div>
            </Stack>


        );
    }

}
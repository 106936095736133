import * as React from "react";
import { genKey } from '../../utils/utils';
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import { getTheme, PrimaryButton } from '@fluentui/react';

export interface IProps {
    heading: string;
    description: string[];
    name: string;
}

export interface IState {
    hover: boolean
}

export class CardWelcome extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            hover: false
        }
    }

    render() {
        const theme = getTheme();
        const isMobile = document.documentElement.clientWidth < 800 ? true : false;
        return (
            <div style={{ boxShadow: theme.effects.elevation16, padding: 20, backgroundColor: 'white', width: isMobile ? 320 : 1160 }}>
                <div>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation>{this.props.name ? this.props.name.toUpperCase() : ""}</h3>
                    {
                        this.props.description.map(description => {
                            return (
                                <div style={{ paddingTop: 20}} key={genKey("cardItem")}>
                                    <Translation>{(t) => t(description)}</Translation>
                                </div>
                            );
                        })
                    }
                    <a onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} href="https://careers.hugoboss.com/global/en/hugo-boss-group-onboarding" target="_blank">
                        <PrimaryButton
                            styles={{
                                flexContainer: {
                                    flexDirection: 'row-reverse'
                                }
                            }}
                            text={i18n.t("description.more")}
                            ariaLabel="zur Onboarding Seite"
                            iconProps={{ iconName: 'Forward' }}
                        />
                    </a>
                </div>
            </div>
        );
    }
}
import React from 'react';
import { genKey } from '../../utils/utils';
import DOMPurify from 'dompurify';

import Header from '../../cmps/Header';
import Content from '../../cmps/Content';
import Footer from "../../cmps/Footer";
import { CardsActive } from '../../cmps/EmployeeCards/CardsActive';

import { Translation } from 'react-i18next';
import { CardHello } from '../../cmps/EmployeeCards/CardHello';
import { CardOrder } from '../../cmps/EmployeeCards/CardOrder';
import { CardAddress } from '../../cmps/EmployeeCards/CardAddress';
import i18n from '../../i18n';
import Config from '../../config.json';


class Employee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            deviceOptions: [],
            laptopDevices: [],
            headsetDevices: [],
            bagDevices: [],
            templates: [],
            template: [],
            employee: this.props.location.state ? this.props.location.state.employeeName : "",
            shipping: this.props.location.state ? this.props.location.state.shipping : false,
            shippingSelected: false,

            selectedLaptop: null,
            selectedBag: null,
            selectedHeadset: null,
            keyboardNeeded: false,

            street: "",
            city: "",
            zip: "",
            country: "",

            lng: i18n.language
        }

        this.onRadioValueChangeLaptop = this.onRadioValueChangeLaptop.bind(this);
        this.onRadioValueChangeBag = this.onRadioValueChangeBag.bind(this);
        this.onRadioValueChangeHeadset = this.onRadioValueChangeHeadset.bind(this);
        this.onKeyboardChange = this.onKeyboardChange.bind(this);
        this.onLanguageSwitch = this.onLanguageSwitch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onShippingSelectionChange = this.onShippingSelectionChange.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
    }

    componentDidMount() {
        if (!this.state.employee) {
            this.getEmployee();
        }
        this.getNotebooks();
        this.getHeadsets();
        this.getBags();
    }

    onRadioValueChangeLaptop(event) {
        this.setState({
            selectedLaptop: parseInt(event.currentTarget.value)
        });
    }

    onRadioValueChangeBag(event) {
        this.setState({
            selectedBag: parseInt(event.currentTarget.value)
        });
    }

    onRadioValueChangeHeadset(event) {
        //console.log("onRadioValueChangeHeadset fired: " + event.currentTarget.value);
        this.setState({
            selectedHeadset: parseInt(event.currentTarget.value)
        });
    }

    onKeyboardChange(event, isChecked) {
        this.setState({ keyboardNeeded: isChecked });
    }

    onShippingSelectionChange(event: React.MouseEvent<HTMLElement>, option: IChoiceOptionGroup) {
        if (option.key == "shipping") {
            this.setState({ shippingSelected: true });
        }
        else {
            this.setState({ shippingSelected: false });
        }
    }

    onAddressChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
        console.log(event.target.id);
        switch (event.target.id) {
            case "street":
                this.setState({ street: newValue });
                break;
            case "city":
                this.setState({ city: newValue });
                break;
            case "zip":
                this.setState({ zip: newValue });
                break;
            case "country":
                this.setState({ country: newValue });
                break;
            default:
                console.log(event.target.id);
                break;
        }
    }

    onLanguageSwitch(lng) {
        console.log("Employee onLanguageSwitch : " + lng);
        this.setState({
            lng: lng
        });
        this.getNotebooks();
        this.getHeadsets();
    }

    handleSubmit(event) {
        event.preventDefault();

        const address = this.state.street + ", " + this.state.zip + " " + this.state.city + ", " + this.state.country;

        const cleanAddress = DOMPurify.sanitize(address);

        fetch(Config.REACT_APP_BACKEND_URL + "/choices", {
            method: "POST",
            body: JSON.stringify({
                //  MitarbeiterId: this.props.location.state.id,
                token: this.props.match.params.token,
                laptopid: parseInt(this.state.selectedLaptop),
                headsetid: parseInt(this.state.selectedHeadset),
                bagid: parseInt(this.state.selectedBag),
                keyboardNeeded: this.state.keyboardNeeded,
                address: cleanAddress
                // Vorgesetzter: this.props.location.state.supervisor,
                // VorgesetzterEmail: this.props.location.state.supervisorEmail,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then(() => {
                this.props.history.push('/EmployeeMicrosite/' + this.props.match.params.token + '')
            })
    }

    getKey() {
        return "contentEmployee_" + Math.floor(Math.random() * 1000000);
    }
    render() {
        const { laptopDevices, headsetDevices, bagDevices, shipping, shippingSelected } = this.state;
        const enable = this.state.shippingSelected ? (this.state.street !== "" && this.state.city !== "" && this.state.zip !== "" && this.state.country !== "" && this.state.selectedHeadset !== null && this.state.selectedLaptop !== null && this.state.selectedBag !== null) : (this.state.selectedHeadset !== null && this.state.selectedLaptop !== null && this.state.selectedBag !== null);
        const isMobile = document.documentElement.clientWidth < 800 ? true : false;
        const headerContent = (
            <div>
                {
                    isMobile ? 
                    <div>
                        <h2 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{t => t("description.heySecondPart").toUpperCase()}</Translation></h2>
                    </div>    
                    :
                    <div>
                        <h1 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{t => t("description.heySecondPart").toUpperCase()}</Translation></h1>
                    </div> 
                }
            </div>
        );
        const content = [
            <CardHello heading="description.additionalInfo" description={["description.employeeWelcomeText","description.employeeWelcomeText2"]} key={genKey("cardhello")} />,
            (<CardsActive elements={this.mapDevices("laptops", laptopDevices, this.onRadioValueChangeLaptop, "selectedLaptop")} heading="description.laptop" showInfos={true} key={genKey("cards")} cardsPerRow={3} bulletsMax={this.getDevicesBulletsMax("laptops", laptopDevices)} lng={this.state.lng} />),
            (<CardsActive elements={this.mapDevices("bags", bagDevices, this.onRadioValueChangeBag, "selectedBag")} bulletsMax={this.getDevicesBulletsMax("bags", bagDevices)} heading="description.bagHeadline" showInfos={true} key={genKey("cards")} cardsPerRow={3} description="description.bagText" lng={this.state.lng} />),
            (<CardsActive elements={this.mapDevices("headsets", headsetDevices, this.onRadioValueChangeHeadset, "selectedHeadset")} heading="description.headsetHeadline" showInfos={true} key={genKey("cards")} cardsPerRow={3} bulletsMax={this.getDevicesBulletsMax("headsets", headsetDevices)} lng={this.state.lng} />),
            <CardAddress shipping={shipping} shippingSelected={shippingSelected} onShippingSelectionChange={this.onShippingSelectionChange} onAddressChange={this.onAddressChange} />,
            <CardOrder heading="description.orderFooter.heading" enabled={enable} key={genKey("cardorder")} />
        ];
        return (
                <form onSubmit={this.handleSubmit}>
                    <Header headerContent={headerContent} />
                    <Content content={content} lng={this.state.lng}/>
                    <Footer callback={this.onLanguageSwitch}/>
                </form>
        )
    }

    mapDevices(type, devices, callback, stateName) {
        const lng = this.state.lng;
        let confDelimiter = ";";
        if(type === "headsets") {
            confDelimiter = ",";
        }
        const elements = devices.map(item => {
            let bullets = lng === "de" ? item.Konfiguration.split(confDelimiter) : item.KonfigurationEn.split(confDelimiter);
            bullets = bullets.map(i => {
                return i.replace("+", "").trim(); 
            });

            return {
                id: item.Id,
                key: type + "_" + item.Id,
                name: item.Title,
                description: lng === "de" ? item.Beschreibung : item.BeschreibungEn,
                image: this.imageDecode(item.Templatebild),
                bullets: bullets,
                callback: callback,
                active: this.state[stateName] === item.Id
            };
            
        });
        //console.log("mapDevices lng : " + lng);
        //console.log(devices);
        return elements;
    }

    getDevicesBulletsMax(type, devices) {
        const lng = this.state.lng;
        let confDelimiter = ";";
        if(type === "headsets") {
            confDelimiter = ",";
        }
        let bulletsMax = 0;
        for(let item of devices) {
            let bullets = lng === "de" ? item.Konfiguration.split(confDelimiter) : item.KonfigurationEn.split(confDelimiter);
            bullets = bullets.map(i => {
                return i.replace("+", "").trim(); 
            });
            const bulletsCount = bullets.reduce((sum, i) => {
                //console.log("sum: " + sum);
                //console.log("i: "  + i);
                const liHeight = i.length > 33 ? 40 : 20;
                sum = sum + liHeight;
                return sum;
            }, 0);
            if(bulletsCount > bulletsMax) {
                bulletsMax = bulletsCount;
            }
        }
        //console.log("getDevicesBulletsMax " + type);
        //console.log(bulletsMax);
        return bulletsMax;
    }



    getNotebooks() {
        var notebooksURL = Config.REACT_APP_BACKEND_URL + "/notebooks?token=" + this.props.match.params.token
        fetch(notebooksURL)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        isLoaded: true,
                        laptopDevices: res
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getHeadsets() {
        var headsetURL = Config.REACT_APP_BACKEND_URL + "/headsets"
        fetch(headsetURL)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        isLoaded: true,
                        headsetDevices: res
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getBags() {
        var bagURL = Config.REACT_APP_BACKEND_URL + "/bags"
        fetch(bagURL)
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        isLoaded: true,
                        bagDevices: res
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getEmployee() {
        fetch(Config.REACT_APP_BACKEND_URL + "/employee?token=" + this.props.match.params.token, {
            headers: {
                accept: "application/json;odata=verbose"
            }
        })
            .then(res => res.json())
            .then(result => {
                this.setState({
                    employee: result.name,
                    shipping: result.Shipping != null ? result.Shipping : false
                });
            },
                error => {
                    this.setState({
                        isLoaded: true
                    });
                }
            )
    }

    imageDecode(imgData) {
        var urlImg = JSON.parse(imgData);
        return Config.REACT_APP_BACKEND_URL + "/image?path="+ urlImg.serverRelativeUrl;
    }
}
export default Employee;

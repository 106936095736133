import React from 'react';
import { Stack } from '@fluentui/react';

export interface IProps {
    name: string;
    initials: string;
    image: any;
}

export class Avatar extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const styleRoundImg = {
            height: 80,
            width: 80,
            minHeight: 80,
            minWidth: 80,
            borderRadius: '50%',
            backgroundColor: 'grey',
            backgroundImage: 'url(' + this.props.image + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
        };
        return (
            <Stack horizontal tokens={{ childrenGap: 25 }}>
                <div style={styleRoundImg}>
                    {
                        !this.props.image &&
                        <h1>{this.props.initials}</h1>
                    }
                </div>
                <div style={{ height: 80, display: 'flex', alignItems: 'center' }}>
                    <h5>{this.props.name}</h5>
                </div>
            </Stack>
        )
    }
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        detection: {
            order: ["localStorage", "navigator"],
            lookupQuerystring: "lng",
            lookupLocalStorage: 'i18nextLng',
            caches: ["localStorage"]
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    description: {
                        admin: 'mydevice@hugoboss.com',
                        error404Headline: 'Error 404, this Page does not exist',
                        error404Message: 'Please specify an existing page in the URL.',
                        error500Headline: '500 - Internal Server Error.',
                        error500Message: 'Something went wrong on our end. Please try again at a different time.',
                        headline: 'MyDevice for',
                        errorMessage: 'An error has occurred, please try again or contact the administrator.',
                        validationMessage: 'Please select an option.',
                        welcome: 'Welcome to the Team',
                        subTextManager: 'MyDevice Dashboard - All to do`s in one place.',
                        subTextEmployee: 'MyDevice Dashboard - All to do`s and information in one place.',
                        generalHeadline: 'Welcome to HUGO BOSS',
                        generalSubText: 'To navigate to the pages please use the menu at the top of the navigation bar.',
                        micrositeManager: 'Microsite Manager',
                        manager: 'Manager',
                        employer: 'Employer',
                        micrositeEmployer: 'Employer Microsite',
                        notesPlaceholder: 'Enter additional information here, e.g. on special devices that have been agreed with the new employee or additional IT devices.',
                        headingAddress: 'Your preferred way: Shipment or Pick-Up',
                        subTextAddress: 'Enter your address where your devices will be sent to.',
                        address: {
                            street: 'Street + No.',
                            number: 'No.',
                            zipCode: 'Zip Code',
                            city: 'City',
                            country: 'Country',
                            labelShipping: 'Shipment',
                            labelPickUp: 'Pick-Up',
                            labelShippingToggle: 'Select if you want to receive your devices via shipment or pickup at the campus on your first day. If you are currently moving into a new apartment or do not have a permanent address, we recommend selecting the "Pick-Up" option at our IT-Service Desk.',
                            noteShipping: 'Please note: If you are located outside of Germany or the European Union, you will need to pick up the package in person on your first day.'
                        },
                        orderFooter: {
                            heading: "Order Hardware",
                            help: "If you need help choosing your future work tool, don't hesitate to email:",
                            help2: "We would be happy to advise you on your selection",
                            order: 'By clicking on "Order", your choice will be forwarded directly to your manager and IT.',
                            order2: "Please note that your choice is final and cannot be exchanged or returned after ordering.",
                            orderButton: "Order selected devices",
                            orderButtonInfo: "Select one device from each category at first. Then you can send the order."
                        },
                        footer: {
                            headlineContact: "Contact",
                            headlineLegal: "Legal",
                            headlineDiscover: "Discover",
                            contactTel: "+49 7123 94-0",
                            contactFax: "+49 7123 94-80259",
                            contactForm: "Contact Form",
                            follow: "Follow us",
                            faq: "FAQ",
                            imprint: "Imprint",
                            privacy: "Privacy Statement",
                            termsOfUse: "Cookie Settings / Terms of Use",
                            companyPrv: "HUGO BOSS Inc. Privacy Statement",
                            companyCeo: "Yasal Bilgiler",
                            companyOrg: "Organigram",
                            companyAct: "UK Modern Slavery Act / CA Supply Chain Disclosure",
                            piracy: "product piracy",
                            app: "App",
                            country: "Change Country: ",
                            onlineStore: "App at Online Store",
                            inspirationHugo: "HUGO Inspiration",
                            inspirationBoss: "BOSS Inspiration",
                            jobs: "Jobs at HUGO BOSS",
                            investors: "Investors"
                        },
                        manager: {
                            welcomeHeading: "What to offer ",
                            welcomeText: "Please classify the activities of your new employee in one of the three work types. We kindly ask you to select in the following process the needed software, smartphone as well as the tariff of your new employee.",
                            welcomeText1: "Select one of the three work types",
                            welcomeText2: "Select the needed Software.",
                            welcomeText3: "Select a Smartphone and the Tariff.",
                            selectTemplateOptionHeading: "Select needed Software",
                            selectMobileHeading: "Select Mobile and Tariff if required",
                            noTemplate: "Please select a template to see the software options.",
                            phoneRequired: "Is a mobile phone required?",
                            tariffs: "Smartphone tariff",
                            policy: "Exceptions are Global Marketing & Brand Communications and the Creative Divisions. You are free to choose your iPhone depending on your area of responsibility and agreement with your superior.",
                            noteEnable: "add Note",
                            sendMailButton: "if needed, you can send another email",
                            sendMailToEmployee: "Send email to employee",
                            mailToEmployeeSended: "The email to the employee has been sent",
                            thName: "Employee",
                            thManager: "Manager",
                            thStatus: "Status",
                            thAction: "Action",
                            selectTemplate: "Set Tech-Template"
                        },

                        keyboardNeeded: "Additional Keyboard needed?",
                        selectDevice: "Select device",
                        select: "Select",
                        selected: "Selected",
                        save: "Save",
                        notes: "Notes",

                        //Employee site
                        more: 'DISCOVER MORE',
                        hey: 'Hello',
                        heySecondPart: 'Let us choose your equipment',
                        employeeWelcomeText: 'To ensure that you have the perfect tools for your work, please select your preferred work tool and headset.',
                        employeeWelcomeText2: '',
                        employeeWelcomeText3: 'All the devices suggested here have already been adapted to your needs with your future supervisor.',
                        employeeWelcomeText4: 'So you can`t go wrong with the selection, it only depends on your individual taste.',
                        laptop: 'Your laptop:',
                        bagHeadline: 'Your Laptop Sleeve:',
                        bagText: '',
                        bagText2: 'On the one hand, the bags serve to protect electronic devices, but they also make it easier to transport work materials/equipment around campus.',
                        headsetHeadline: 'Your Headset:',
                        headsetText: 'You have your own headset for uncomplicated telephoning via Microsoft TEAMS',
                        headsetText2: 'You can choose between the variants listed here.',
                        nextSteps: 'Next steps',
                        nextStepsDescription: 'Welcome to HUGO BOSS! To ensure that you have the best possible start at HUGO BOSS, we are already in the process of preparing your IT equipment. Below, you will find a list of all the open tasks that need to be completed:',
                        contactPersonHeading: "Your Contact Persons",
                        workplaceHeading: "Your Workplace",
                        workplaceDescriptionHeading: "Holy-Allee 3, Level 2, 2/45",
                        workplaceDescription: "To ensure the best possible start for you at HUGO BOSS, we are already preparing your IT equipment. Please find below all open tasks listed:",
                        canteenHeading: "Where to eat",
                        canteenDescription: "We have an excellent canteen on our Hugo Boss campus where you will be provided with the finest delicacies every day. However, if you would like to bring your own lunch, each floor is equipped with a kitchen, including microwave, cutlery and plates.",
                        approachHeading: "Getting there",
                        approachDescription: "DAY 1: On your first day, please make your way to the visitor parking lot located directly at the main gate in Holy-Allee. Once you arrive, enter through the main gate and proceed to the reception area where our team will warmly welcome you.<br/><br/>HUGO BOSS AG Visitor Parking<br/>Holy-Allee 3 | 72555 Metzingen<br/><br/>DAY 2: Starting from your second day of work, you have the option to utilize any of the parking facilities available around the HUGO BOSS campus using your employee ID card. One of the recommended parking areas is the HUGO BOSS parking garage located at Carl-Zeiss-Strasse 2, which offers over 1000 parking spaces.<br/><br/>HUGO BOSS parking garage<br/>Carl- Zeiss - Strasse 2 | 72555 Metzingen<br/>",
                        approachDescription2: "These are the best options for public transport.",
                        welcomeHeading: "Welcome to our Team!",
                        welcomeP1: "To learn more about your first days at HUGO BOSS, follow the button below to visit our onboarding site.",
                        welcomeP2: "",
                        superiorHeading: "Your Contacts",
                        superior: "Superior",
                        selectHardware: "Your laptop",
                        additionalInfo: "It's your choice",
                        toDoHeading: "TO DO",
                        additionalInfoHeading: "ADDITIONAL INFORMATION",
                        statusHeading: "STATUS",
                        nextStepsDeadline: "DEADLINE",
                        nextStepsAction: "Take Action",
                        language: "Language",
                        statusOpen: "Open",
                        "Abgeschlossen": "Completed",
                        "Warten auf Mitarbeiter": "Waiting for employee",
                        "Aktion erforderlich": "Action required"
                    }
                }
            },
            de: {
                translation: {
                    description: {
                        admin: 'mydevice@hugoboss.com',
                        error404Headline: 'Fehler 404, diese Seite haben wir nicht gefunden',
                        error404Message: 'Geben Sie in der URL eine existierende Unterseite an.',
                        error500Headline: '500 - Interner Serverfehler.',
                        error500Message: 'Da ging etwas schief auf unserer Seite. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                        headline: 'MyDevice für',
                        errorMessage: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator.',
                        validationMessage: 'Bitte wählen Sie eine Option aus.',
                        welcome: 'Herzlich Willkommen',
                        subTextManager: 'MyDevice Dashboard - Alle to do`s an einem Ort.',
                        subTextEmployee: 'MyDevice Dashboard - Alle to do`s und Informationen an einem Ort.',
                        generalHeadline: 'Herzlich Willkommen bei HUGO BOSS',
                        generalSubText: 'Um zu den Seiten zu navigieren nutzen Sie bitte das Menü oben in der Navigationsleiste.',
                        micrositeManager: 'Microsite Manager',
                        manager: 'Manager',
                        employer: 'Mitarbeiter',
                        micrositeEmployer: 'MA Microsite',
                        notesPlaceholder: 'Gebe hier zusätzliche Informationen ein, z.B. zu speziellen Geräten die mit dem neuen Mitarbeiter abgesprochen wurden oder zusätzliche IT Geräte.',
                        headingAddress: 'Deine bevorzugte Art: Versand oder Abholung',
                        subTextAddress: 'Gebe Deine Adresse ein, an die Deine Geräte versendet werden sollen.',
                        address: {
                            street: 'Straße + Hausnr.',
                            number: 'Hausnr.',
                            zipCode: 'Postleitzahl',
                            city: 'Stadt',
                            country: 'Land',
                            labelShipping: 'Versand an Privatadresse',
                            labelPickUp: 'Abholung',
                            labelShippingToggle: 'Wähle, ob Deine Geräte direkt zu Dir nach Hause gesendet werden sollen oder Du Deine Geräte an Deinem ersten Arbeitstag auf dem Campus abholen möchtest. Wenn Du gerade eine neue Wohnung beziehst oder keinen festen Wohnsitz hast, empfehlen wir, die Option "Abholung" bei unserem IT-Service Desk zu wählen.',
                            noteShipping: 'Wenn Du dich außerhalb Deutschlands oder der EU befindest, musst Du Deine Geräte an Deinem ersten Tag persönlich bei uns abholen.'
                        },
                        orderFooter: {
                            heading: "Bestelle Hardware",
                            help: "Falls du Unterstützung bei der Auswahl deines zukünftigen Arbeitsgeräts benötigst, zögere nicht und wende dich per E-Mail an. Wir beraten dich gerne bei deiner Auswahl.",
                            help2: "Wir beraten dich gerne bei deiner Auswahl.",
                            order: 'Mit dem Klick auf "Bestellen" wird deine Auswahl direkt an deinen Vorgesetzten und an die IT weitergeleitet.',
                            order2: "Beachte bitte, dass deine Auswahl endgültig ist und nach dem Bestellen nicht mehr getauscht oder zurückgegeben werden kann.",
                            orderButton: "Ausgewählte Geräte bestellen",
                            orderButtonInfo: "Wählen Sie erst ein Objekt aus jeder Kategorie. Dann können Sie die Bestellung abschicken."
                        },
                        footer: {
                            headlineContact: "Kontakt",
                            headlineLegal: "Rechtliches",
                            headlineDiscover: "Entdecke",
                            contactTel: "+49 7123 94-0",
                            contactFax: "+49 7123 94-80259",
                            contactForm: "Kontaktformular",
                            follow: "Folge uns",
                            faq: "FAQ",
                            imprint: "Impressum",
                            privacy: "Datenschutzbestimmungen",
                            termsOfUse: "Cookie Einstellungen Nutzungsbedingungen",
                            companyPrv: "HUGO BOSS Inc. Privacy Statement",
                            companyCeo: "Yasal Bilgiler",
                            companyOrg: "Organigramm",
                            companyAct: "UK Modern Slavery Act / CA Supply Chain Disclosure",
                            piracy: "Produktpiraterie",
                            app: "App",
                            country: "Anderes Land auswählen: ",
                            onlineStore: "App im Online Store",
                            inspirationHugo: "HUGO Inspiration",
                            inspirationBoss: "BOSS Inspiration",
                            jobs: "Jobs bei HUGO BOSS",
                            investors: "Investoren"
                        },
                        manager: {
                            welcomeHeading: "Wähle die Hardware für ",
                            welcomeText: "Bitte ordne die Tätigkeiten deines neuen Mitarbeiters einem der drei Arbeitstypen zu. Wir bitten dich, im folgenden Prozess die benötigte Software, das Smartphone sowie den Tarif Ihres neuen Mitarbeiters auszuwählen.",
                            welcomeText1: "Wähle einen Arbeitstyp für deinen neuen Mitarbeiter",
                            welcomeText2: "Wähle die benötigte Software.",
                            welcomeText3: "Wähle ein Smartphone und den Tarif.",
                            selectTemplateOptionHeading: "Wähle die nötige Software",
                            selectMobileHeading: "Wähle Smartphone und Vertrag wenn benötigt",
                            noTemplate: "Bitte wählen Sie eine Vorlage aus, um die Softwareoptionen anzuzeigen.",
                            phoneRequired: "Wird ein Smartphone benötigt?",
                            tariffs: "Smartphone Tarif",
                            policy: "Ausnahmen sind Global Marketing & Brand Communications und die Creative Divisions. Je nach Aufgabenbereich und Absprache mit Ihrem Vorgesetzten können Sie Ihr iPhone frei wählen.",
                            noteEnable: "Bemerkung hinzufügen",
                            sendMailButton: "Wenn nötig, kann eine neue Email gesendet werden.",
                            sendMailToEmployee: "E-Mail an Mitarbeiter senden",
                            mailToEmployeeSended: "Die E-Mail an den Mitarbeiter wurde versendet.",
                            thName: "Mitarbeiter",
                            thManager: "Vorgesetzter",
                            thStatus: "Status",
                            thAction: "Aktion",
                            selectTemplate: "Treffe Technik-Vorauswahl"
                        },
                        keyboardNeeded: "Zusätzliche Tastatur benötigt?",
                        selectDevice: "Gerät auswählen",
                        select: "Auswählen",
                        selected: "Ausgewählt",
                        save: "Speichern",
                        notes: "Bemerkung",

                        //Employee site
                        more: 'mehr erfahren',
                        hey: 'Hallo',
                        heySecondPart: 'Lass uns dein Equipment auswählen',
                        employeeWelcomeText: 'Damit du von Beginn an mit einem auf dich zugeschnittenen Arbeitsgerät ausgestattet bist, bitten wir dich nun dein neues Arbeitsgerät und ein Headset auszuwählen.',
                        employeeWelcomeText2: '',
                        employeeWelcomeText3: 'Alle hier vorgeschlagenen Geräte sind schon mit deinem zukünftigen Vorgesetzten auf deine Bedürfnisse angepasst.',
                        employeeWelcomeText4: 'Du kannst also bei der Auswahl nichts falsch machen, es richtet sich nur nach deinem individuellen Geschmack.',
                        laptop: 'Dein Laptop:',
                        bagHeadline: 'Dein Laptopsleeve:',
                        bagText: '',
                        bagText2: 'Die Taschen dienen einerseits zum Schutz der elektronischen Geräte, erleichtern aber auch das Transportieren von Arbeitsmaterialien/-geräten auf dem Campus.',
                        headsetHeadline: 'Dein Headset:',
                        headsetText: 'Dir steht für das unkomplizierte Telefonieren über Microsoft TEAMS ein eigenes Headset zur Verfügung.',
                        headsetText2: 'Du kannst Dich zwischen den hier aufgeführten Varianten entscheiden.',
                        nextSteps: 'Deine ersten Schritte bei HUGO BOSS',
                        nextStepsDescription: 'Willkommen bei HUGO BOSS! Um Dir einen optimalen Start bei HUGO BOSS zu ermöglichen, sind wir bereits dabei, Deine IT-Ausstattung vorzubereiten. Nachfolgend findest Du eine Liste aller offenen Aufgaben, die noch zu erledigen sind:',
                        contactPersonHeading: "Deine Kontaktpersonen",
                        workplaceHeading: "Der HUGO BOSS Campus, dein neuer Arbeitsplatz",
                        workplaceDescriptionHeading: "Holy-Allee 3, Ebene 2, Raum 2/45",
                        workplaceDescription: "Bekomme einen ersten Eindruck des HUGO BOSS Campus in Metzingen und einen Überblick über deine ersten Tage, indem du unserer Onboarding-Seite folgst.",
                        canteenHeading: "Wo gibt es etwas zu essen?",
                        canteenDescription: "Wir haben eine ausgezeichnete Kantine auf unserem HUGO BOSS Campus wo du jeden Tag mit feinsten Köstlichkeiten versorgt wirst. Falls du allerdings dein Mittagessen selber mitbringen möchtest, ist jede Etage mit einer Küche ausgestattet, inklusive Mikrowelle, Besteck und Tellern.",
                        approachHeading: "Wo kann ich parken?",
                        approachDescription: "TAG 1: An deinem ersten Tag steht Dir unser Besucherparkplatz direkt an der Hauptpforte, in der Holy-Allee zur Verfügung.<br/><br/>HUGO BOSS AG Visitor Parking<br/>Holy-Allee 3<br/>72555 Metzingen<br/><br/>TAG 2: Ab Deinem zweiten Arbeitstag kannst du einen der vielen Parkmöglichkeiten rund um den HUGO BOSS Campus mit Deinem Mitarbeiterausweis nutzen, wie beispielsweise das HUGO BOSS Parkhaus in der Carl-Zeiss-Straße 2 mit über 1000 Parkplätzen.<br/><br/>HUGO BOSS parking garage<br/>Carl- Zeiss - Strasse 2<br/>72555 Metzingen",
                        approachDescription2: "Hier sind die besten optionen für den öffentlichen Nahverkehr.",
                        welcomeHeading: "WILLKOMMEN IN UNSEREM TEAM!",
                        welcomeP1: "Nichts ist aufregender als die ersten Tage in einem neuem Team. Erfahre auf unserer Onboarding Seite mehr über die ersten Tage bei HUGO BOSS:",
                        welcomeP2: "",
                        superiorHeading: "Deine Ansprechpartner",
                        superior: "Vorgesetzter",
                        selectHardware: "Hardware wählen",
                        additionalInfo: "Wähle dein zukünftiges Equipment",
                        toDoHeading: "TO DO",
                        additionalInfoHeading: "ZUSÄTZLICHE INFORMATIONEN",
                        statusHeading: "STATUS",
                        nextStepsDeadline: "DEADLINE",
                        nextStepsAction: "Starte ToDo",
                        language: "Sprache",
                        statusOpen: "Noch offen",
                        "Abgeschlossen": "Abgeschlossen",
                        "Warten auf Mitarbeiter": "Warten auf Mitarbeiter",
                        "Aktion erforderlich": "Aktion erforderlich"
                    }
                }
            }
        }
    });

export default i18n;
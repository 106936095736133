import * as React from "react";
import { Translation } from 'react-i18next';
import { ChoiceGroup, IChoiceGroupOption, IStackItemStyles, IStackTokens, Stack, TextField, Toggle } from '@fluentui/react';

import { getTheme } from '@fluentui/react';
import i18n from "../../i18n";

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 30,
    padding: "10px 0px",
};

const horizontalGapStackTokensMobile: IStackTokens = {
    childrenGap: 5,
    padding: 5,
};

const nonShrinkingStackItemStyles: IStackItemStyles = {
    root: {
        overflow: 'hidden',
        width: '75%',
    },
};

const options: IChoiceGroupOption[] = [
    { key: 'pick-up', text: i18n.t("description.address.labelPickUp") },
    { key: 'shipping', text: i18n.t("description.address.labelShipping") }
]

export interface IProps {
    heading: string;
    shipping: boolean;
    shippingSelected: boolean;
    onShippingSelectionChange: any;
    onAddressChange: any;
}

export interface IState {
    lng: string;
}

export class CardAddress extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            lng: i18n.language,
        }
    }

    render() {
        const theme = getTheme();
        const isMobile = document.documentElement.clientWidth < 800;

        return (
            <div style={{ width: isMobile ? 360 : 1160, display: this.props.shipping ? "block" : "none" }}>
                <div style={{ padding: isMobile ? 0 : 30 }}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t("description.headingAddress").toUpperCase()}</Translation></h3>
                </div>
                <div style={{ paddingLeft: isMobile ? 0 : 30, paddingRight: isMobile ? 0 : 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Stack tokens={{ childrenGap: 30 }}>
                        <div>
                            {/*<Toggle label={i18n.t("description.address.labelShippingToggle")} onText={i18n.t("description.address.labelShipping")} offText={i18n.t("description.address.labelPickUp")} onChange={this.props.onShippingSelectionChange}></Toggle>*/}
                            <ChoiceGroup defaultSelectedKey="pick-up" options={options} onChange={this.props.onShippingSelectionChange} label={i18n.t("description.address.labelShippingToggle")} ></ChoiceGroup>
                            <div style={{ marginTop: 30 }}><span><Translation>{t => t("description.address.noteShipping")}</Translation></span></div>
                            {this.props.shippingSelected && <div>
                                <div style={{ marginTop: 30 }} ><Translation>{t => t("description.subTextAddress")}</Translation></div>
                                <Stack horizontal tokens={isMobile ? horizontalGapStackTokensMobile : horizontalGapStackTokens}>
                                    <Stack.Item disableShrink grow={12} styles={nonShrinkingStackItemStyles}><TextField id="street" label={i18n.t("description.address.street")} onChange={this.props.onAddressChange}></TextField></Stack.Item>
                                {/*    <Stack.Item grow={1}><TextField label={i18n.t("description.address.number")}></TextField></Stack.Item>*/}
                                </Stack>
                                <Stack horizontal tokens={isMobile ? horizontalGapStackTokensMobile : horizontalGapStackTokens}>
                                    <Stack.Item disableShrink grow={8} styles={nonShrinkingStackItemStyles}><TextField id="city" label={i18n.t("description.address.city")} onChange={this.props.onAddressChange}></TextField></Stack.Item>
                                    <Stack.Item grow={4}><TextField id="zip" label={i18n.t("description.address.zipCode")} onChange={this.props.onAddressChange}></TextField></Stack.Item>
                                </Stack>
                                <Stack horizontal tokens={isMobile ? horizontalGapStackTokensMobile : horizontalGapStackTokens}>
                                    <Stack.Item grow={4}><TextField id="country" label={i18n.t("description.address.country")} onChange={this.props.onAddressChange}></TextField></Stack.Item>
                                </Stack>
                            </div>}
                        </div>
                    </Stack>
                </div>
            </div >
        );
    }
}
import * as React from 'react';
import { CommandBarButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, DetailsRow, IDetailsListProps, DetailsHeader } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { TextField } from '@fluentui/react/lib/TextField';
import i18n from 'i18next';
import { Trans, Translation } from 'react-i18next';
import { getStatusColor } from '../../utils/utils';
import './DetailsList.scss';
import Config from '../../config.json';

const classNames = mergeStyleSets({
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

const controlStyles = {
    root: {
        color: '#fff',
    },
    fieldGroup: {
        borderRadius: '3px',
        background: 'transparent',
        height: '27px',

        ':hover': {
            borderColor: 'rgb(0, 120, 212)'
        },
    },
};

const gridStyles = {
    root: {
        background: 'transparent',
    },
};

const gridHeaderStyles = {
    root: {
        background: '#f5f5f5',
        paddingTop: '0',
        marginTop: '15px',
    },
};

export interface IDetailsListDataGridState {
    columns: IColumn[];
    items: IData[];
    employees: any;
    error: any;
    isLoaded: boolean;
    status: string;
    showModal: boolean;

}

export interface IData {
    Id: number;
}

export interface IDetailsDataGridProps {
    state?: string;
    userName: string;
    userEmail: string;
    onMailSendClick: any;
    lng: string;
    msalContext: any;
}


export class DetailsListDataGrid extends React.Component<IDetailsDataGridProps, IDetailsListDataGridState> {

    constructor(props: IDetailsDataGridProps) {
        super(props);


        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Id',
                fieldName: 'Id',
                minWidth: 1,
                maxWidth: 10,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                className: "table-data",
                onRender: (item) => {
                    return (
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                            <span data-tip data-for={"Id_" + item.Id}>{item.Id}</span>
                        </div>
                    );
                },
                isPadded: true,
            },
            {
                key: 'column2',
                name: i18n.t("description.manager.thName"),
                fieldName: 'Name',
                minWidth: 25,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item) => {
                    return (
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                            <span >{item.Name}</span>
                        </div>
                    );
                }
            },
            {
                key: 'column3',
                name: i18n.t("description.manager.thManager"),
                fieldName: 'Superior',
                minWidth: 25,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item) => {
                    return (
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                            <span >{item.Superior}</span>
                        </div>
                    );
                }

            },
            {
                key: 'column4',
                name: i18n.t("description.manager.thStatus"),
                fieldName: 'Status',
                minWidth: 60,
                maxWidth: 130,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item) => {
                    return (
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                            <span style={{
                                padding: 5,
                                borderRadius: 5,
                                color: getStatusColor(item.Status)
                            }}
                            >
                                <Trans i18nKey={'description.' + item.Status}></Trans>
                            </span>
                        </div>
                    );
                }

            },
            {
                key: 'column5',
                name: i18n.t("description.manager.thAction"),
                minWidth: 60,
                maxWidth: 100,
                onRender: (item) => {
                    if (item.Status === 'Aktion erforderlich') {
                        return (
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <Link
                                    data-tip
                                    data-for={"Manager_" + item.Id}
                                    to={{ pathname: "/Manager", state: { token: item.Token, employeeName: item.Name, Id: item.Id } }}
                                >
                                    <PrimaryButton
                                        text={i18n.t("description.manager.selectTemplate").toUpperCase()}
                                        ariaLabel="select Template"
                                        iconProps={{ iconName: 'Forward' }}
                                        size={1}
                                    />
                                </Link>
                            </div>
                        );
                    }
                    if (item.Status === 'Warten auf Mitarbeiter') {
                        return (
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <CommandBarButton onClick={this.props.onMailSendClick} value={item.Id} data-tip data-for={"Command_Mail"} iconProps={{ iconName: "Mail" }} text={i18n.t("description.manager.sendMailButton")} />
                                <ReactTooltip id={"Command_Mail"} place="top" effect="solid"><Trans i18nKey={"description.manager.sendMailToEmployee"}></Trans></ReactTooltip>
                            </div>
                        );
                    }
                },
                isPadded: true,
            }
        ];

        this.state = {
            isLoaded: false,
            showModal: false,
            error: null,
            items: [],
            employees: [],
            columns: columns,
            status: "",
        };
    }
    public componentDidMount() {
        if (this.props.userEmail) {
            this.fetchEmployees();
        }
    }

    public render() {
        const { error, isLoaded, columns, items } = this.state;      //isCompactMode,isModalSelection,
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <div className={classNames.controlWrapper}>
                        <TextField label={"Filter:"} onChange={this._onChangeText} styles={controlStyles} />
                    </div>
                    <DetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        getKey={this._getKey}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                </div>
            );
        }
    }

    private _getKey(item: any, index?: number): string {
        return item.key;
    }

    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            items: text ?
                this.state.employees.filter((i: any) => (
                    [i.Name,
                    i.Id,
                    i.Superior,
                    i.Status].join("|")).toLowerCase().indexOf(text.toLowerCase()) > -1)
                : this.state.employees,
        });
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    }

    fetchEmployees() {
        const account = this.props.msalContext.accounts[0];
        const accessTokenRequest = {
            scopes: [Config.REACT_APP_MSAL_SCOPE],
            account: account,
        };
        var that = this;
        this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest)
            .then(function (response: any) {
                fetch(Config.REACT_APP_BACKEND_URL + "/NewEmployee", {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + response.accessToken
                    },
                    body: JSON.stringify(account.idTokenClaims.email),
                    method: "POST",
                })
                    .then(res => res.json())
                    .then(result => {
                        that.setState({
                            isLoaded: true,
                            employees: [...that.state.employees, ...result],
                            items: result
                        });
                    },
                        error => {
                            that.setState({
                                isLoaded: true
                            });
                        }
                    )
            });
        var userEmail = this.props.userEmail;
    }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
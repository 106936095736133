import * as React from "react";
import { Translation } from 'react-i18next';
import { PrimaryButton, Stack} from '@fluentui/react';

import { getTheme } from '@fluentui/react';
import i18n from "../../i18n";

export interface IProps {
    heading: string;
    enabled: boolean;
}

export class CardOrder extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const theme = getTheme();
        const isMobile = document.documentElement.clientWidth < 800;
        return (
            <div style={{width: isMobile ? 360 : 1160}}>
                <div style={{ padding: isMobile ? 0 : 30}}>
                    <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation></h3>
                </div>
                <div style={{ paddingLeft: isMobile ? 0 : 30, paddingRight: isMobile ? 0 : 30, paddingTop: 30, paddingBottom: 30 }}>
                    <Stack tokens={{childrenGap: 30}}>
                        <div>
                            <div><Translation>{t=> t("description.orderFooter.help")}</Translation></div>
                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <div style={{fontWeight: 'bold'}}>
                                <Translation>{t=> t("description.admin")}</Translation>
                            </div>
                        </div>
                        <div>
                            <div><Translation>{t=> t("description.orderFooter.order")}</Translation></div>
                            <div><Translation>{t=> t("description.orderFooter.order2")}</Translation></div>

                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <Translation>
                                {t => {
                                    return(
                                        <PrimaryButton 
                                            text={t("description.orderFooter.orderButton")}
                                            type="submit"
                                            disabled={!this.props.enabled}
                                            title={this.props.enabled ? "" : i18n.t("description.orderFooter.orderButtonInfo")}
                                        />
                                    );
                                    }
                                }
                            </Translation>
                        </div>
                    </Stack>
                    

                </div>
            </div>
        );
    }
}
import React from 'react';
import { withMsal } from "@azure/msal-react";
import { DetailsListDataGrid } from '../../components/DetailList/DetailsList';
import { Trans, Translation } from 'react-i18next';
import { login, genKey } from '../../utils/utils';

import Header from '../../cmps/Header';
import Content from '../../cmps/Content';
import Footer from '../../cmps/Footer';

import i18n from '../../i18n';
import Config from '../../config.json';

import { getTheme, MessageBar, MessageBarType } from '@fluentui/react';

class MicrositeManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            userName: '',
            userEmail: '',
            mailSent: this.props.match.params.selectTemplateSuccess === "selectTemplateSuccess" ? true : false,
            error: false,
            lng: i18n.language
        }
        this.onLanguageSwitch = this.onLanguageSwitch.bind(this);
        this.onMailSendClick = this.onMailSendClick.bind(this);
    }

    componentDidMount() {
        login(this.props.msalContext);
    }

    componentDidUpdate() {
        login(this.props.msalContext);
    }

    onLanguageSwitch(lng) {
        //console.log("ManagerMicrosite onLanguageSwitch : " + lng);
        this.setState({
            lng: lng
        });
    }

    render() {
        const theme = getTheme();
        { console.log(this.props.msalContext) }
        const managerName = this.props.msalContext.accounts.length > 0 ? this.getFirstName(this.props.msalContext.accounts[0].name) : "Manager";
        const headerContent = (
            <div>
                <h1 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{t => t("description.hey").toUpperCase()}</Translation></h1>
                <h1 style={{ fontFamily: 'AvertaPE-Extrabold' }}>{managerName}</h1>
            </div>
        );
        const content = [

        ];
        if(this.props.msalContext.accounts.length > 0) {
            content.push(
                <div style={{ boxShadow: theme.effects.elevation16, padding: 30, backgroundColor: 'white' }} key={genKey("CntMsMngrList")}>
                    <Translation>
                        {t => <DetailsListDataGrid msalContext={this.props.msalContext} onMailSendClick={this.onMailSendClick} userName={this.props.msalContext.accounts[0].name} userEmail={this.props.msalContext.accounts[0].idTokenClaims.email} lng={t.language} />}
                    </Translation>
                </div>
            );
        }
        if(this.state.mailSent) {
            content.push(
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => this.setState({mailSent: false})}
                    key={genKey("msMngrMsgMail")}
                >
                    <Trans i18nKey="description.manager.mailToEmployeeSended"></Trans>
                </MessageBar>
            );
        }
        if(this.state.error) {
            content.push(
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => this.setState({error: false})}
                    key={genKey("msMngrMsgError")}
                >
                    <Trans i18nKey="description.errorMessage"></Trans>
                </MessageBar>
            );
        }
        return (
            <div>
                <Header headerContent={headerContent} />
                <Content content={content} />
                <Footer callback={this.onLanguageSwitch} />
            </div>
        )
    }

    getFirstName(name) {
        var nameArr = name.split(",");
        return nameArr[1].toUpperCase();
    }

    onMailSendClick(event) {
        const id = event.currentTarget.value;
        const account = this.props.msalContext.accounts[0];
        const accessTokenRequest = {
            scopes: [Config.REACT_APP_MSAL_SCOPE],
            account: account,
        };
        var that = this;
        this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest)
            .then(function (response) {
                fetch(Config.REACT_APP_BACKEND_URL + "/employeeMail", {
                    method: "POST",
                    body: JSON.stringify(id),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + response.accessToken
                    }
                })
                    .then(() => {
                        //alert(i18n.t("description.manager.mailToEmployeeSended"));
                        that.setState({ mailSent: true, error: false });
                    })
                    .catch((error) => {
                        alert(i18n.t("description.errorMessage"));
                        that.setState({ error: true, mailSent: false });
                    });
            });
    }

}
export default MicrositeManager = withMsal(MicrositeManager);
import { InteractionStatus } from "@azure/msal-browser";

export function setContainerHeight(selector) {
    const elements = document.querySelectorAll(selector);
    var heights = [];
    for (let i = 0; i < elements.length; i++) {
        heights.push(elements[i].clientHeight);
    }
    const maxHeight = Math.max(...heights);

    for (let i = 0; i < elements.length; i++) {
        elements[i].style.height = maxHeight + "px";
    }
}

export function isDe() {
    const lng = localStorage.getItem("i18nextLng");
    return lng === "de" || lng === "de-DE";
}

export function login(msalContext, callback) {
    const isAuthenticated = msalContext.accounts.length > 0;
    const msalInstance = msalContext.instance;

    // If a user is not logged in and authentication is not already in progress, invoke login
    if (!isAuthenticated && msalContext.inProgress === InteractionStatus.None) {
        const redirectStartPage = window.location.href;
        msalInstance.loginRedirect({ redirectStartPage, scopes: ["User.Read", "email"] }).then(() => {
            if (callback) {
                callback();
            }
        });
    }
    else {
        if (callback) {
            callback();
        }
    }
}

export function getStatusColor(status) {
    if (status === 'Aktion erforderlich') {
        return "B51F29";
    } else if (status === 'Warten auf Mitarbeiter') {
        return "#987147";
    }
    return "#80BA27";
}

export function genKey(prefix) {

    return prefix + "_" + Math.floor(Math.random() * 1000000);
}

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import { createRoot } from 'react-dom/client';
//import "./assets/scss/_global.scss";
import "./assets/scss/_headings.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './i18n';
//import 'bootstrap/dist/css/bootstrap.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/msal";

const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<MsalProvider instance={msalInstance}><App /></MsalProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { genKey } from '../../utils/utils';
import { Translation } from 'react-i18next';
import { Stack, PrimaryButton } from '@fluentui/react';

import i18n from "../../i18n";

import { getTheme } from '@fluentui/react';


interface IProps {
    heading: string;
    description: string;
    disabled: boolean;
}

interface IState {
    
}

export class CardSave extends React.Component <IProps , IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        const theme = getTheme();
        const styleCard = { 
            boxShadow: theme.effects.elevation16, 
            padding: 30, 
            backgroundColor: 'white' 
        };
        return (
            <div style={styleCard}>
                <Stack tokens={{childrenGap: 30}}>
                    <div style={{ paddingLeft: 30}}>
                        <h3 style={{ fontFamily: 'AvertaPE-Extrabold' }}><Translation>{(t) => t(this.props.heading).toUpperCase()}</Translation></h3>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <PrimaryButton 
                            text={i18n.t("description.save")} 
                            type='submit'
                            disabled={this.props.disabled}
                        />
                    </div>
                </Stack>
            </div>
        );
    }

}